import { useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { StrikethroughAction } from '../../molecules';
import { useToolbarState } from '../../providers';

export const Strikethrough = () => {
  const [editor] = useLexicalComposerContext();
  const { isStrikethrough } = useToolbarState(['isStrikethrough']);

  const onStrikethroughSelect = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
  }, [editor]);

  return (
    <StrikethroughAction onClick={onStrikethroughSelect} isActive={isStrikethrough} aria-label='Format Strikethrough' />
  );
};

Strikethrough.displayName = 'Strikethrough';
