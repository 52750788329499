import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ButtonStyleProps, ButtonStyleValues } from '../button.type';
import { baseButtonStyle, pressedStyle, hoverStyle } from './common.styles';
import { getStyleValues } from './helpers';

const primaryTheme: ButtonStyleValues = {
  backgroundColor: theme.colors.primary50,
  color: theme.colors.white,
  focusRingColor: theme.colors.primary50,
  hoverColor: theme.colors.primary60,
  pressed: {
    backgroundColor: theme.colors.primary70,
    color: theme.colors.primary20,
  },
  disabled: {
    backgroundColor: theme.colors.primary20,
    borderColor: theme.colors.status.disabled,
    color: theme.colors.white,
    secondaryColor: theme.colors.neutral20,
  },
  loading: {
    backgroundColor: theme.colors.primary10,
    borderColor: theme.colors.primary30,
  },

  /**
   * Destructive object structure can optionally match every value of base styles
   * to override them during the recursive merge.
   */
  destructive: {
    pressed: {
      backgroundColor: theme.colors.status.criticalPressed,
      color: theme.colors.critical20,
    },
    focusRingColor: theme.colors.status.critical,
    backgroundColor: theme.colors.status.critical,
    color: theme.colors.white,
    hoverColor: theme.colors.status.criticalHover,
    disabled: {
      backgroundColor: theme.colors.critical20,
    },
    loading: {
      backgroundColor: theme.colors.critical10,
      borderColor: theme.colors.critical30,
    },
  },
};

export const primaryStyles = ({ size, isLoading, iconOnly, destructive }: ButtonStyleProps) => {
  const styles = getStyleValues(primaryTheme, destructive || false);

  return [
    baseButtonStyle({
      focusRingColor: styles.focusRingColor,
      size: size ?? 'small',
      iconOnly,
    }),
    css`
      background: ${styles.backgroundColor};
      border: none;
      color: ${styles.color};
    `,
    isLoading
      ? css`
          border: 1px solid ${styles.loading.borderColor};
          background: ${styles.loading.backgroundColor};
          &[aria-disabled='true'] {
            box-shadow: none;
            color: ${styles.disabled.color};
          }
        `
      : css`
          :hover {
            ${hoverStyle({
              hoverColor: styles.hoverColor,
            })};
          }
          &[aria-disabled='true'] {
            background: ${styles.disabled.backgroundColor};
            border-color: ${styles.disabled.backgroundColor};
            box-shadow: none;
            color: ${styles.disabled.color};
          }
          ${pressedStyle({ pressed: styles.pressed })}
        `,
  ];
};
