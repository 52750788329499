import { ElementType, ReactNode, forwardRef, KeyboardEvent, MouseEvent, Fragment, useCallback } from 'react';
import { KeyNames } from '../../../constants';
import { FontColorType } from '../../../helpers';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useStyles } from '../../../use-styles';
import { ContextMenu, ContextMenuActions } from '../../context-menu/context-menu';
import { Text } from '../../text';
import { useChatItemContext } from '../chat-item';

export type BubbleProps = {
  text?: ReactNode;
  subText?: ReactNode;
  bubbleIcon?: ReactNode;
  onClick?: (e?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
  backgroundColor?: string;
  maxWidth?: string;
  contextActions?: ContextMenuActions;
  contextMenuReturnFocus?: boolean;
  textColor?: FontColorType;
};

type PolymorphicBubbleProps<C extends ElementType = 'li'> = PolymorphicComponentPropWithRef<C, BubbleProps>;
type BubbleType = <C extends ElementType = 'li'>(props: PolymorphicBubbleProps<C>) => React.ReactNode;

export const Bubble: BubbleType = forwardRef(
  <C extends ElementType = 'li'>(
    {
      as,
      text,
      subText,
      bubbleIcon,
      onClick,
      backgroundColor,
      maxWidth,
      contextActions,
      contextMenuReturnFocus,
      textColor,
    }: PolymorphicBubbleProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'li';
    const { direction, hoverProps, isFuture, isPaused, hasError } = useChatItemContext();
    const bubbleStyles = useStyles('ChatItem', 'bubble', {
      direction,
      isInteractive: !!onClick,
      isFuture,
      isPaused,
      error: hasError,
      maxWidth,
      customTextColor: !!textColor,
    });

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === KeyNames.Enter || event.key === KeyNames.Space) {
        onClick?.(event);
      }
    };

    const Wrapper = useCallback(
      contextActions
        ? ({ children }: { children: JSX.Element }) => (
            <ContextMenu actions={contextActions} returnFocus={contextMenuReturnFocus}>
              {children}
            </ContextMenu>
          )
        : Fragment,
      [JSON.stringify(contextActions)]
    );

    return (
      <Wrapper>
        <Component
          ref={ref}
          css={[bubbleStyles, backgroundColor && !hasError && { backgroundColor }]}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          {...hoverProps}
          tabIndex={0}
        >
          {bubbleIcon}
          <div className='bubble-content'>
            {typeof text === 'string' ? (
              <Text size='large' className='bubble-text' color={textColor}>
                {text}
              </Text>
            ) : (
              text
            )}
            {subText &&
              (typeof subText === 'string' ? (
                <Text size='medium' className='bubble-text' color={textColor}>
                  {subText}
                </Text>
              ) : (
                subText
              ))}
          </div>
        </Component>
      </Wrapper>
    );
  }
);
