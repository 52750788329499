import { BeautifulMentionsTheme } from 'lexical-beautiful-mentions';

const dynamicFieldTheme: BeautifulMentionsTheme = {
  '{{': 'dynamic-field-theme',
  '{{Focused': 'dynamic-field-theme-focused',
};

export const defaultTheme = {
  beautifulMentions: dynamicFieldTheme,
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  quote: 'editor-quote',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
    olDepth: [
      'editor-ordered-list-ol1',
      'editor-ordered-list-ol2',
      'editor-ordered-list-ol3',
      'editor-ordered-list-ol4',
      'editor-ordered-list-ol5',
    ],
  },
  image: 'editor-image',
  link: 'editor-link',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'editor-text-underline',
    strikethrough: 'editor-text-strikethrough',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
    code: 'editor-text-code',
  },
};
