/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ReplyIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M6.987 2.041a1.138 1.138 0 0 0-.254.111A534.86 534.86 0 0 0 1.299 6.53c-.316.3-.378.852-.138 1.236.081.13.431.421 2.764 2.305a427.59 427.59 0 0 0 2.765 2.22c.252.174.627.218.92.108.212-.079.439-.295.539-.511l.078-.168.007-.82.008-.82h.065c.096 0 .663.123.919.2a7.313 7.313 0 0 1 2.249 1.137 9.469 9.469 0 0 1 1.545 1.49c.171.204.358.397.431.447a.99.99 0 0 0 1.436-.39l.086-.176.009-.787c.021-1.866-.158-3.095-.599-4.128-.855-2.002-2.735-3.099-5.728-3.343l-.412-.034-.008-.881-.008-.882-.078-.158a1.014 1.014 0 0 0-1.162-.534M6.267 5.4c0 .207.012.313.049.409a.991.991 0 0 0 .601.598c.116.044.241.056.83.074 1.508.048 2.543.257 3.377.682.948.484 1.457 1.204 1.703 2.412.029.143.053.292.053.331v.07l-.153-.117a11.273 11.273 0 0 0-1.34-.842c-1.224-.632-2.396-.941-3.814-1.006-.316-.015-.418-.01-.559.026a.991.991 0 0 0-.66.531c-.064.124-.073.174-.082.465-.006.18-.019.327-.03.327-.01 0-.609-.476-1.331-1.058-1.173-.946-1.308-1.063-1.272-1.099.109-.109 2.566-2.08 2.594-2.081.025-.002.034.071.034.278'
    />
  </Icon>
);
