import { forwardRef, useState, useEffect, Ref, useMemo } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { Icon } from '@frontend/icons';
import { KeyNames } from '../../constants';
import { getInitials } from '../../helpers';
import { getFirstLastInitials } from '../../helpers/get-initials';
import { useImageReady } from '../../hooks';
import { useStyles } from '../../use-styles';
import { Styles } from '../../use-styles/use-styles';
import { useTooltip } from '../tooltip';
import { BottomIcon, AvatarDot } from './atoms';
import type { SizeProps as AvatarSize } from './avatar.styles';
import { getColor } from './helpers';

export interface AvatarProps {
  alt?: string;
  disableClick?: boolean;
  firstName?: string;
  lastName?: string;
  id?: string;
  isWeave?: boolean;
  isUser?: boolean;
  userId?: string;
  name?: string;
  onClick?: (e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  size?: AvatarSize;
  src?: string;
  style?: React.CSSProperties;
  title?: string;
  children?: React.ReactNode;
  bypassColor?: { fill: string; text: string };
  css?: Styles;
  disableHoverLabel?: boolean;
}

const smallerThanLargeVariants: AvatarSize[] = ['medium', 'small', 'xs'];

const WeaveAvatar: React.FC<{ size: AvatarSize }> = ({ size }) => (
  <Icon name='weave-logo' size={smallerThanLargeVariants.includes(size) ? 16 : 24} />
);

const InitialsAvatar: React.FC<{
  firstName?: string;
  lastName?: string;
  name?: string;
  color: { fill: string; text: string };
}> = ({ firstName, lastName, name, color }) => (
  <figcaption style={{ backgroundColor: color.fill, color: color.text }}>
    {!!firstName && !!lastName ? getFirstLastInitials(firstName, lastName) : getInitials(name || '')}
  </figcaption>
);

const DefaultAvatar: React.FC<{ size: AvatarSize }> = ({ size }) => (
  <Icon name='user' size={smallerThanLargeVariants.includes(size) ? 16 : 24} />
);

const ImageAvatar: React.FC<{ src: string; name: string; onError: () => void }> = ({ src, name, onError }) => {
  const imgProps = useImageReady();
  return <img {...imgProps} onError={onError} src={src} alt={`${name || 'Unnamed'} avatar`} />;
};

const AvatarOptional = forwardRef<HTMLElement, AvatarProps>(
  (
    {
      alt,
      disableClick,
      id,
      userId,
      isWeave,
      isUser = false,
      name,
      firstName,
      lastName,
      onClick,
      size = 'medium',
      src,
      title,
      bypassColor,
      children,
      disableHoverLabel,
      ...rest
    },
    ref
  ) => {
    const derivedName = firstName && lastName ? `${firstName} ${lastName}` : name;
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'bottom', hoverDelay: 300 });
    const { ref: tooltipTriggerRef, ...restTriggerProps } = triggerProps;
    const [isError, setIsError] = useState(false);
    const isInteractive = !!onClick && !disableClick;
    const avatarContainer = useStyles('Avatar', 'container', { isInteractive, size });
    const avatarContent = useStyles('Avatar', 'content');
    const sizeStyles = useStyles('Avatar', 'size');
    const normalizedSrc = !isError ? src : '';

    useEffect(() => {
      if (isError) {
        setIsError(false);
      }
    }, [src]);

    const identifier = userId || derivedName || 'default';
    const color = useMemo(() => bypassColor || getColor(identifier, isUser), [bypassColor, identifier, isUser]);

    const renderAvatarContent = () => {
      if (isWeave) return <WeaveAvatar size={size} />;
      if (!normalizedSrc && firstName && lastName)
        return <InitialsAvatar firstName={firstName} lastName={lastName} color={color} />;
      if (!normalizedSrc && derivedName) return <InitialsAvatar name={derivedName} color={color} />;
      if (!normalizedSrc && !derivedName) return <DefaultAvatar size={size} />;
      return <ImageAvatar src={normalizedSrc ?? ''} name={derivedName || ''} onError={() => setIsError(true)} />;
    };

    const handleClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      if (disableClick) {
        return;
      } else onClick?.(e);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === KeyNames.Enter || event.key === KeyNames.Space) {
        handleClick(event);
      }
    };

    return (
      <>
        <figure
          ref={composeRefs(ref, tooltipTriggerRef as Ref<HTMLElement>)}
          aria-label={`${derivedName || 'Unnamed'} avatar`}
          css={[avatarContainer, sizeStyles?.[size]]}
          id='avatar'
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={isInteractive ? 0 : -1}
          {...(disableHoverLabel ? undefined : restTriggerProps)}
          {...rest}
        >
          <div
            css={[
              avatarContent,
              !normalizedSrc && derivedName && css({ backgroundColor: color.fill, color: color.text }),
            ]}
          >
            {renderAvatarContent()}
          </div>
          {children}
        </figure>
        {!disableHoverLabel && <Tooltip {...tooltipProps}>{title || derivedName || 'Unnamed avatar'}</Tooltip>}
      </>
    );
  }
);

AvatarOptional.displayName = 'Avatar';

const AvatarNamespace = Object.assign(AvatarOptional, {
  Dot: AvatarDot,
  BottomIcon: BottomIcon,
});

export { AvatarNamespace as Avatar };
