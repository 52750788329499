import { useCallback, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useAnimationControls } from 'framer-motion';

type ImageState = {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  retryCount: number;
};

type ImageAction = { type: 'LOAD_START' } | { type: 'LOAD_SUCCESS' } | { type: 'LOAD_ERROR' } | { type: 'RETRY' };

const initialState: ImageState = {
  status: 'idle',
  retryCount: 0,
};

export const MAX_RETRY_LIMIT = 3;

const imageReducer = (state: ImageState, action: ImageAction): ImageState => {
  switch (action.type) {
    case 'LOAD_START':
      return { ...state, status: 'loading' };
    case 'LOAD_SUCCESS':
      return { ...state, status: 'loaded' };
    case 'LOAD_ERROR':
      return { ...state, status: 'error' };
    case 'RETRY':
      return {
        ...state,
        status: 'loading',
        retryCount: state.retryCount + 1,
      };
    default:
      return state;
  }
};

export const useImageLoader = (src: string, onRetry?: () => void) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [state, dispatch] = useReducer(imageReducer, initialState);
  const [isImageAlreadyLoaded, setIsImageAlreadyLoaded] = useState(false);
  const imageControls = useAnimationControls();

  const updateImageLoadStatus = useCallback(
    (img: HTMLImageElement | null) => {
      if (!img) {
        dispatch({ type: 'LOAD_START' });
        return;
      }

      if (img.complete) {
        if (img.naturalWidth > 0) {
          setIsImageAlreadyLoaded(true);
          dispatch({ type: 'LOAD_SUCCESS' });
        } else {
          dispatch({ type: 'LOAD_ERROR' });
        }
      } else {
        dispatch({ type: 'LOAD_START' });
      }
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    updateImageLoadStatus(imgRef.current);
  }, [src]);

  const handleImgRef = useCallback((img: HTMLImageElement | null) => {
    imgRef.current = img;
    updateImageLoadStatus(img);
  }, []);

  const onLoad = useCallback(() => {
    dispatch({ type: 'LOAD_SUCCESS' });
  }, []);

  const onError = useCallback(() => {
    dispatch({ type: 'LOAD_ERROR' });
  }, []);

  useLayoutEffect(() => {
    switch (state.status) {
      case 'loaded':
        imageControls.start({
          scale: 1,
          transition: { duration: isImageAlreadyLoaded ? 0 : 0.3 },
        });
        break;
      case 'loading':
        imageControls.set({ scale: 1.2 });
        break;
      case 'error':
        imageControls.set({ scale: 0 });
        break;
      default:
        break;
    }
  }, [state.status, imageControls, isImageAlreadyLoaded]);

  const handleRetry = useCallback(() => {
    if (state.retryCount < MAX_RETRY_LIMIT) {
      onRetry?.();
      dispatch({ type: 'RETRY' });
    }
  }, [onRetry, state.retryCount]);

  return useMemo(
    () => ({
      state,
      isImageAlreadyLoaded,
      imageControls,
      handleImgRef,
      onLoad,
      onError,
      handleRetry,
    }),
    [state, isImageAlreadyLoaded, imageControls, handleImgRef, onLoad, onError, handleRetry]
  );
};
