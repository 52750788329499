import { useState } from 'react';
import { Tab, TabBar } from './common';
import { getKeyboardHandler } from './common/utils';

export type UseTabFilterResponse = {
  activeTab: string;
  changeTab: (id: string) => void;
  filterProps: {
    activeTab: string;
    onKeyDown: (e: React.KeyboardEvent) => void;
  };
  getTabProps: (id: string) => {
    'aria-selected': boolean;
    id: string;
    onClick: () => void;
    role: string;
    tabIndex: number;
  };
  Tab: typeof Tab;
  TabFilter: typeof TabBar;
};

const handleKeyDown = getKeyboardHandler('button');

export function useTabFilter(initialTab: string): UseTabFilterResponse {
  const [activeTab, setActiveTab] = useState(initialTab);
  const getTabProps = (id: string) => {
    const isActive = activeTab === id;
    return {
      id,
      'aria-selected': isActive,
      onClick: () => {
        setActiveTab(id);
      },
      role: 'tab',
      tabIndex: isActive ? 0 : -1,
    };
  };

  return {
    activeTab,
    changeTab: setActiveTab,
    filterProps: {
      activeTab,
      onKeyDown: handleKeyDown,
    },
    getTabProps,
    TabFilter: TabBar,
    Tab,
  };
}
