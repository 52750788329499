import { forwardRef } from 'react';
import { useThemeValues } from '../../../hooks';
import { useStyles } from '../../../use-styles';
import { Button } from '../../button/button.component';
import type { ButtonRef } from '../button-type';
import { SecondaryButtonProps } from './types';

/** @deprecated Instead, please use `<Button variant='secondary'>` from `@frontend/design-system` */
export const SecondaryButton = forwardRef<ButtonRef, SecondaryButtonProps>(
  ({ children, destructive, size = 'small', trackingId, type = 'button', ...rest }, ref) => {
    const styles = useStyles('SecondaryButton', { destructive, size });
    const theme = useThemeValues();

    return theme.name === 'spark' ? (
      <Button
        variant='secondary'
        type={type}
        size={size === 'tiny' ? 'large' : size}
        destructive={destructive}
        {...rest}
        ref={ref}
        data-trackingid={trackingId}
      >
        {children}
      </Button>
    ) : (
      <button css={styles} type={type} {...rest} ref={ref} data-trackingid={trackingId}>
        {children}
      </button>
    );
  }
);
