import type { KeyboardEvent } from 'react';
import { createNumberMask } from 'text-mask-addons';
import { KeyNames } from '../../../../constants/key-names';
import MaskedInput, { conformToMask } from '../../../../helpers/text-mask';
import type { NumericProps } from '../../hooks/types';
import type { MaskConfig } from '../mask-types';
import type { InputProps } from '../types';
import { getMaskConfigProps } from '../utils';
import { incrementValue, decrementValue, floatCharsOnly } from './utils';

export const getNumberMask = ({
  allowDecimal = true,
  allowNegative = false,
  includeThousandsSeparator,
  prefix = '',
  suffix = '',
}: Omit<NumericProps, 'max' | 'min'>) =>
  createNumberMask({
    allowDecimal,
    allowNegative,
    includeThousandsSeparator,
    prefix,
    suffix,
  });

export const NumberInput = ({
  allowDecimal = true,
  allowNegative,
  includeThousandsSeparator,
  onChange,
  prefix,
  max = 99999,
  min,
  suffix,
  value = '',
  onKeyDown,
  ...rest
}: InputProps<'number'> & MaskConfig) => {
  const mask = getNumberMask({
    allowDecimal,
    allowNegative,
    includeThousandsSeparator,
    prefix,
    suffix,
  });

  const handleChange = ({ target }) => {
    onChange({
      name: rest.name,
      value: floatCharsOnly(target.value),
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key, target } = event;
    if (key === KeyNames.Up || key === KeyNames.Down) {
      const value = floatCharsOnly((target as HTMLInputElement).value);
      const action = key === KeyNames.Up ? incrementValue : decrementValue;
      const next = action({
        value,
        allowDecimal,
        max,
        min: min ?? allowNegative ? undefined : 0,
      });
      onChange({ name: rest.name, value: `${next}` });
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const { maskConfigProps, nonConfigProps } = getMaskConfigProps(rest);

  return (
    <MaskedInput
      {...nonConfigProps}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      mask={mask}
      autoComplete='off'
      inputMode='decimal'
      pattern='[0-9]*'
      type='text'
      value={value.trim() ? conformToMask(value, mask, maskConfigProps).conformedValue : value}
    />
  );
};
