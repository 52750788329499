import { CSSProperties, ReactNode, useEffect } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { Placement } from '@floating-ui/react-dom-interactions';
import { CaretDownIconSmall } from '../../../icon';
import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';
import {
  PopoverMenuItemProps,
  PopoverMenuItem,
  usePopoverMenu,
  PopoverMenu,
  PopoverDialog,
  usePopoverDialog,
} from '../../popover';
import { PrimaryButton } from '../primary';

export type MultiActionButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  actions?: (PopoverMenuItemProps<'button'> & {
    fill?: CSSProperties['fill'];
  } & Required<Pick<PopoverMenuItemProps<'button'>, 'onClick'>>)[];
  label: string | ReactNode;
  disabled?: boolean;
  menuPlacement?: Placement;
  onClick: (e: React.MouseEvent) => void;
  size?: 'large' | 'small';
  type?: 'submit' | 'reset' | 'button';
  trackingId?: string;
  actionType?: 'menu' | 'dialog';
  Dialog?: ReactNode;
  dialogProps?: Omit<Parameters<typeof usePopoverDialog>[0], 'placement'>;
  iconButtonStyleOverrides?: Interpolation<Theme>;
};

export const MultiActionButton = ({
  actions,
  disabled,
  label,
  menuPlacement = 'top-start',
  onClick,
  size = 'large',
  type,
  trackingId,
  actionType = 'menu',
  Dialog,
  dialogProps,
  iconButtonStyleOverrides = {},
  ...rest
}: MultiActionButtonProps) => {
  const {
    getTriggerProps: getMenuTriggerProps,
    getMenuProps,
    getItemProps,
    isOpen: isMenuOpen,
    close: menuClose,
  } = usePopoverMenu({
    placement: menuPlacement,
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        alignmentAxis: 0,
      },
    },
  });

  const {
    getTriggerProps: getDialogTriggerProps,
    getDialogProps,
    isOpen: isDialogOpen,
    close: dialogClose,
  } = usePopoverDialog<HTMLButtonElement>({
    placement: menuPlacement,
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        alignmentAxis: 0,
      },
    },
  });

  const triggerProps = actionType === 'menu' ? getMenuTriggerProps() : getDialogTriggerProps();
  const menuProps = getMenuProps();
  const isOpen = actionType === 'menu' ? isMenuOpen : isDialogOpen;

  const iconButtonStyle = useStyles('MultiActionButton', {
    size,
    active: isOpen,
  });

  useEffect(() => {
    if (disabled) {
      if (actionType === 'menu') menuClose();
      if (actionType === 'dialog') dialogClose();
    }
  }, [disabled]);

  return (
    <div
      className='multi-action-btn'
      css={css`
        position: relative;
        display: flex;
      `}
      {...rest}
    >
      <PrimaryButton
        css={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
        onClick={onClick}
        disabled={disabled}
        type={type}
        size={size}
        trackingId={trackingId}
      >
        {label}
      </PrimaryButton>
      <NakedButton
        {...triggerProps}
        type='button'
        css={[iconButtonStyle, iconButtonStyleOverrides]}
        disabled={disabled}
      >
        <CaretDownIconSmall
          css={css`
            transition: transform 200ms ease-out;
            transform: rotate(${isOpen ? 180 : 0}deg);
          `}
        />
      </NakedButton>
      {actionType === 'menu' && actions && (
        <PopoverMenu {...menuProps}>
          {actions.map(({ label, Icon, fill, onClick, ...rest }, index) => (
            <PopoverMenuItem
              key={label}
              Icon={Icon}
              css={{ fill: fill }}
              {...getItemProps({ onClick: onClick, index })}
              {...rest}
            >
              {label}
            </PopoverMenuItem>
          ))}
        </PopoverMenu>
      )}
      {actionType === 'dialog' && (
        <PopoverDialog {...dialogProps} {...getDialogProps()} returnFocus={false}>
          {Dialog}
        </PopoverDialog>
      )}
    </div>
  );
};
