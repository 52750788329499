import React, { forwardRef } from 'react';
import { contextFactory } from '../../../hooks';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useStyles } from '../../../use-styles';
import { useAccordionContext } from '../provider/accordion.provider';

interface AccordionItemContext {
  value: string;
  hasMultiLocations?: boolean;
  trackingId?: string;
}

export const [AccordionItemContext, useAccordionItemContext] = contextFactory<AccordionItemContext>();

type Props = {
  value: string;
  hasMultiLocations?: boolean;
  trackingId?: string;
};

type AccordionItemProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, Props>;

type AccordionItemComponent = <C extends React.ElementType = 'div'>(props: AccordionItemProps<C>) => React.ReactNode;

export const AccordionItem: AccordionItemComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    { children, className, as, value, hasMultiLocations, trackingId, ...others }: AccordionItemProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const { isItemActive, variant, distance, showBoxShadow } = useAccordionContext();
    const accordionItemStyles = useStyles('Accordion', 'accordionItem', { variant, distance, showBoxShadow });

    const Component = as || 'div';

    return (
      <AccordionItemContext.Provider value={{ value, hasMultiLocations, trackingId }}>
        <Component css={accordionItemStyles} ref={ref} data-active={isItemActive(value) || undefined} {...others}>
          {children}
        </Component>
      </AccordionItemContext.Provider>
    );
  }
);
