import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { toHex, getLuminance } from 'color2k';
import { HexColorPicker } from 'react-colorful';
import useEyeDropper from 'use-eye-dropper';
import { theme } from '@frontend/theme';
import { useDebouncedValue } from '../../hooks';
import { CheckIconSmall, EyedropIconSmall } from '../../icon';
import { NakedButton } from '../../naked';
import { PrimaryButton } from '../buttons';
import { TextField, useControlledField } from '../forms';
import { SkeletonLoader } from '../loader';
import { Tabs } from '../tabs';
import { defaultPalette } from './constants';
import { useColorPicker } from './provider';
import { generateColorProgression } from './utils';

export const ColorPickerMain = () => {
  const { hideCustom, hidePalette, onChange, activeColor, onClick, shouldApply } = useColorPicker();
  const [controlledTab, setControlledTab] = useState(hidePalette ? 'custom' : 'palette');

  return (
    <article
      css={css`
        width: 200px;
      `}
    >
      <Tabs
        css={css`
          width: auto;
        `}
        controlledTab={controlledTab}
        onChange={(id) => setControlledTab(id)}
      >
        <Tabs.Bar fullWidth>
          {!hidePalette && (
            <Tabs.Tab id='palette' controls='palette-panel'>
              Palette
            </Tabs.Tab>
          )}
          {!hideCustom && (
            <Tabs.Tab id='custom' controls='custom-panel'>
              Custom
            </Tabs.Tab>
          )}
        </Tabs.Bar>
        {controlledTab === 'palette' && !hidePalette && (
          <div
            css={css`
              width: 100%;
              aspect-ratio: 1/1;
              margin: ${theme.spacing(1.5, 0)};
            `}
          >
            <Palette onChange={shouldApply ? undefined : onChange} />
          </div>
        )}
        {controlledTab === 'custom' && !hideCustom && (
          <CustomColorPicker onChange={shouldApply ? undefined : onChange} />
        )}
      </Tabs>
      {shouldApply && (
        <PrimaryButton
          onClick={() => {
            onChange?.(activeColor);
            onClick?.();
          }}
        >
          Apply Color
        </PrimaryButton>
      )}
    </article>
  );
};

const CustomColorPicker = ({ onChange }: { onChange?: (color: string) => void }) => {
  const { activeColor, setActiveColor } = useColorPicker();

  return (
    <div
      css={css`
        width: 100%;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        margin: ${theme.spacing(1.5, 0)};

        .react-colorful {
          width: 100%;
          flex: 1;
        }

        .react-colorful__last-control {
          height: 10px;
        }

        .react-colorful__pointer {
          width: 15px;
          height: 15px;
        }
      `}
    >
      <HexColorPicker
        color={activeColor}
        onChange={(newColor: string) => {
          setActiveColor(newColor);
          onChange?.(newColor);
        }}
        css={css`
          margin-top: -${theme.spacing(0.5)};
        `}
      />
      <HexInput onChange={onChange} />
    </div>
  );
};

const HexInput = ({ onChange }: { onChange?: (color: string) => void }) => {
  const { activeColor, setActiveColor } = useColorPicker();
  const { open, isSupported } = useEyeDropper();

  const pickColor = useCallback(() => {
    const openPicker = async () => {
      try {
        const color = await open();
        setActiveColor(color.sRGBHex);
        onChange?.(color.sRGBHex);
      } catch (e) {
        console.error(e);
      }
    };
    openPicker();
  }, [open]);

  const textField = useControlledField({ type: 'text', value: activeColor.toUpperCase(), onChange: setActiveColor });

  const debouncedValue = useDebouncedValue(textField.value, 500);

  useEffect(() => {
    // adds the '#' if it's missing and validates the color
    const validatedColor = CSS.supports('color', debouncedValue)
      ? debouncedValue
      : CSS.supports('color', `#${debouncedValue}`)
      ? `#${debouncedValue}`
      : '';
    if (validatedColor) {
      // this loses focuses when the input updates with the new value
      onChange?.(validatedColor);
    }
  }, [debouncedValue]);

  return (
    <div
      css={css`
        display: flex;
        gap: ${theme.spacing(1)};
        align-items: center;
        margin-bottom: -${theme.spacing(0.5)};
      `}
    >
      Hex:
      <TextField
        css={css`
          padding-left: ${theme.spacing(1)};
          padding-right: 0;
          height: 28px;
          border: 1px solid ${theme.colors.neutral50};
        `}
        name='hex-input'
        startAdornment={
          <div
            css={css`
              height: 16px;
              aspect-ratio: 1/1;
              border-radius: ${theme.borderRadius.small};
              background: ${activeColor};
              border: 1px solid gray;
            `}
          />
        }
        label=''
        {...textField}
      />
      {isSupported() && (
        <NakedButton
          css={css`
            height: 100%;
            border-radius: ${theme.borderRadius.small};
            display: flex;
            padding: ${theme.spacing(0, 1)};
            align-items: center;
            justify-content: center;
            border: 1px solid ${theme.colors.neutral50};
          `}
          onClick={pickColor}
        >
          <EyedropIconSmall />
        </NakedButton>
      )}
    </div>
  );
};

const Palette = ({ onChange }: { onChange?: (color: string) => void }) => {
  const { extractedColors, isLoading, palette } = useColorPicker();
  const showExtractedColor = !isLoading && !extractedColors.length;

  return (
    <div
      css={css`
        display: grid;
        grid-gap: ${theme.spacing(1)};
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: max-content;
        width: 100%;
      `}
    >
      {isLoading &&
        new Array(36).fill(undefined).map((item) => (
          <SkeletonLoader
            key={item}
            css={css`
              aspect-ratio: 1/1;
              display: block;
              border-radius: ${theme.borderRadius.full};
            `}
          />
        ))}

      {!showExtractedColor &&
        extractedColors?.slice(0, 6)?.map((val) =>
          generateColorProgression(val).map((color) => {
            return <PaletteButton key={color} color={color} onChange={onChange} />;
          })
        )}
      {!palette &&
        showExtractedColor &&
        defaultPalette.map((color) => <PaletteButton key={color} color={color} onChange={onChange} />)}
      {palette &&
        showExtractedColor &&
        palette.map((color) => <PaletteButton key={color} color={color} onChange={onChange} />)}
    </div>
  );
};

const PaletteButton = ({ color, onChange }: { color: string; onChange?: (color: string) => void }) => {
  const { setActiveColor, activeColor } = useColorPicker();
  const hexColor = toHex(color);

  return (
    <NakedButton
      onClick={() => {
        setActiveColor(hexColor);
        onChange?.(hexColor);
      }}
      css={css`
        aspect-ratio: 1/1;
        position: relative;

        :focus {
          outline: none;
        }

        :focus span::after {
          opacity: 1;
        }
      `}
    >
      <span
        css={css`
          display: block;
          border-radius: ${theme.borderRadius.full};
          position: relative;
          background: ${color};
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          border: 1px solid ${theme.colors.neutral20};

          ::after {
            content: '';
            height: calc(100% + 10px);
            width: calc(100% + 10px);
            display: block;
            background: white;
            z-index: -1;
            border: 2px solid ${theme.colors.primary50};
            border-radius: ${theme.borderRadius.full};
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 150ms ease-out;
          }
        `}
      >
        {activeColor === hexColor && <CheckIconSmall color={getLuminance(color) > 0.7 ? 'default' : 'white'} />}
      </span>
    </NakedButton>
  );
};
