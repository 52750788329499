import type { HintProps, UseHintProps, UseHintResults } from '../hint';
import { useHint } from '../hint';
import { HoverLabel } from './hover-label.component';

type Props = Omit<UseHintProps, 'trigger'>;

type UseHoverLabelResults = Omit<UseHintResults, 'hintProps'> & {
  HoverLabel: typeof HoverLabel;
  labelProps: HintProps;
};

/**
 * @deprecated The `useHoverLabel` hook is no longer recommended and will be removed in a future update.
 * It is advised to use the revamped `useTooltip` hook instead.
 */
export function useHoverLabel(props: Props = {}): UseHoverLabelResults {
  const { hintProps, ...rest } = useHint(props);
  return { ...rest, labelProps: hintProps, HoverLabel };
}
