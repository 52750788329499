/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ReorderIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12.885864,1.26611652 L16.0678445,4.44809704 C16.5559999,4.9362524 16.5559999,5.72770863 16.0678445,6.21586399 C15.5796891,6.70401936 14.7882329,6.70401936 14.3000776,6.21586399 L13.255,5.17 L13.255,18.8 L14.3000776,17.7557089 C14.7556892,17.3000972 15.4755096,17.2697231 15.9663492,17.6645865 L16.0678445,17.7557089 C16.5559999,18.2438642 16.5559999,19.0353205 16.0678445,19.5234758 L16.0678445,19.5234758 L12.885864,22.7054564 C12.3977086,23.1936117 11.6062524,23.1936117 11.118097,22.7054564 L11.118097,22.7054564 L7.93611652,19.5234758 C7.44796116,19.0353205 7.44796116,18.2438642 7.93611652,17.7557089 C8.42427189,17.2675535 9.21572811,17.2675535 9.70388348,17.7557089 L9.70388348,17.7557089 L10.755,18.807 L10.755,5.163 L9.70388348,6.21586399 C9.21572811,6.70401936 8.42427189,6.70401936 7.93611652,6.21586399 C7.44796116,5.72770863 7.44796116,4.9362524 7.93611652,4.44809704 L11.118097,1.26611652 C11.6062524,0.777961159 12.3977086,0.777961159 12.885864,1.26611652 Z'
    />
  </Icon>
);
