import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const editorStyles = ({ isReadonly }: { isReadonly?: boolean }) => css`
  width: 100%;

  .draggable-block {
    border-radius: ${theme.borderRadius.small};
    padding: 3px 1px;
    cursor: grab;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    will-change: transform;
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .draggable-block:active {
    cursor: grabbing;
  }

  .draggable-block:hover {
    background-color: ${theme.colors.neutral10};
  }

  .draggable-line {
    pointer-events: none;
    border-bottom: 2px dotted ${theme.colors.primary50};
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    will-change: transform;
  }

  .editor-container {
    margin: ${theme.spacing(2)} auto ${theme.spacing(2)} auto;
    width: 100%;
    color: ${theme.font.colors.default};
    background: ${theme.colors.white};
    position: relative;
    line-height: ${theme.font.lineHeight};
    font-weight: ${theme.font.weight.regular};
    text-align: left;
  }

  .editor-scroller {
    min-height: 150px;
    border: 0;
    display: flex;
    position: relative;
    outline: 0;
    z-index: 0;
    overflow: auto;
    resize: vertical;
  }

  .editor {
    flex: auto;
    position: relative;
    resize: vertical;
    z-index: -1;
  }

  .editor-inner {
    background: ${theme.colors.white};
    position: relative;
  }

  .editor-input {
    min-height: 150px;
    resize: none;
    font-size: ${theme.font.size.large};
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: ${isReadonly ? theme.spacing(1) : theme.spacing(1, 3.5, 1)};
    caret-color: ${theme.colors.neutral90};
  }

  .editor-placeholder {
    color: ${theme.font.colors.light};
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: ${theme.spacing(1)};
    left: ${isReadonly ? theme.spacing(1) : theme.spacing(3.5)};
    font-size: ${theme.font.size.large};
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-ordered-list-ol1 {
    padding: 0;
    margin: 0;
    list-style-position: outside;
  }
  .editor-ordered-list-ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: outside;
  }
  .editor-ordered-list-ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: outside;
  }
  .editor-ordered-list-ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: outside;
  }
  .editor-ordered-list-ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: outside;
  }

  .editor-text-strikethrough {
    text-decoration: underline line-through;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }
  .editor-nested-listitem:before,
  .editor-nested-listitem:after {
    display: none;
  }

  .editor-link {
    color: ${theme.font.colors.primary};
  }

  .editor-listitem {
    margin: ${theme.spacing(0, 4)};
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    list-style-position: outside;
  }

  .editor-paragraph {
    margin-top: 0;
    line-height: ${theme.spacing(4)};
    font-size: ${theme.fontSize(14)};
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-heading-h1 {
    font-size: ${theme.fontSize(24)};
    color: ${theme.font.colors.default};
    font-weight: ${theme.font.weight.bold};

    margin-top: 0;
    margin-bottom: ${theme.spacing(1.5)};
  }

  .editor-heading-h2 {
    font-size: ${theme.fontSize(18)};
    margin-top: 0;
    color: ${theme.font.colors.default};
    font-weight: ${theme.font.weight.bold};
  }

  .editor-heading-h3 {
    font-size: ${theme.fontSize(16)};
    margin-top: 0;
    color: ${theme.font.colors.default};
    font-weight: ${theme.font.weight.bold};
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: ${theme.colors.neutral40};
  }

  .dynamic-field-theme {
    padding: ${theme.spacing(0.25, 0.5)};
    border: 1px solid ${theme.colors.neutral40};
    font-size: ${theme.font.size.small};
    border-radius: ${theme.borderRadius.small};
    white-space: nowrap;
    transition: 0.2s ease;
  }

  .editor-quote {
    color: ${theme.colors.neutral60};
    border-left: 4px solid ${theme.colors.neutral10};
    padding-left: ${theme.spacing(2)};
    margin: ${theme.spacing(2, 1)};
  }

  .dynamic-field-theme:hover,
  .dynamic-field-theme:focus {
    background-color: ${theme.colors.neutral5};
    border: 1px solid ${theme.colors.neutral10};
    cursor: pointer;
  }

  .dynamic-field-theme-focused {
    position: relative;
    background-color: ${theme.colors.neutral5};
  }

  .dynamic-field-theme-focused::after {
    position: absolute;
    content: '';
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 2px solid ${theme.colors.primary50};
    border-radius: 6px;

    top: -4px;
    left: -4px;
  }
`;
