import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { createGetSpecificChildren, createKeydownHandler } from '../../../helpers';
import { contextFactory } from '../../../hooks';
import { useThemeValues } from '../../../hooks';
import { CaretRightIconSmall } from '../../../icon';
import { NakedButton } from '../../../naked';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useStyles } from '../../../use-styles';
import { INDEX_ROW_ERRORS } from '../context-errors';
import { useIndexRow } from '../provider';
import { IndexRowHead } from './index-row-head';
import { IndexRowTail } from './index-row-tail';
import { IndexRowTitle } from './index-row-title';

interface IndexRowItemContext {
  disabled?: boolean;
}

export const [IndexRowItemContext, useIndexRowItemContext] = contextFactory<IndexRowItemContext>(INDEX_ROW_ERRORS.item);

type Props = {
  disabled?: boolean;
  caretIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type IndexRowItemProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, Props>;

type ChildrenTypes = 'IndexRowHead' | 'IndexRowTitle' | 'IndexRowTail';

type IndexRowItemComponent = (<C extends React.ElementType>(props: IndexRowItemProps<C>) => React.ReactNode) & {
  displayName?: string;
};

export const IndexRowItemOptional: IndexRowItemComponent = forwardRef(
  <C extends React.ElementType = 'button'>(
    { children, as, disabled, onKeyDown, onClick, caretIcon, ...rest }: IndexRowItemProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const { disabled: allDisable, caretIcon: allCaretIcon } = useIndexRow();
    const shouldDisable = disabled ?? allDisable;
    const caretIconToDisplay = caretIcon ?? allCaretIcon;

    const indexRowItemStyles = useStyles('IndexRow', 'indexRowItemStyle', {
      disabled: shouldDisable,
    });
    const { spacing } = useThemeValues();
    const getChild = createGetSpecificChildren<ChildrenTypes>(children);
    const Component = as || NakedButton;

    return (
      <IndexRowItemContext.Provider value={{ disabled: shouldDisable }}>
        <Component
          aria-disabled={shouldDisable}
          data-index-row-item
          onClick={!shouldDisable ? onClick : undefined}
          onKeyDown={createKeydownHandler({
            siblingSelector: '[data-index-row-item]',
            parentSelector: '[data-index-row-parent]',
            shouldActivateOnFocus: false,
            orientation: 'vertical',
            onKeyDown,
          })}
          css={indexRowItemStyles}
          // TODO: Remove any type
          ref={ref as any}
          disabled={shouldDisable}
          {...rest}
        >
          <section
            css={css`
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              gap: ${spacing(3)};
              margin-right: ${spacing(2)};
            `}
          >
            {getChild('IndexRowHead')}
            {getChild('IndexRowTitle')}
            {getChild('IndexRowTail')}
          </section>
          {caretIconToDisplay || caretIconToDisplay === null ? (
            caretIconToDisplay
          ) : (
            <CaretRightIconSmall
              color='light'
              css={[
                css`
                  margin-left: auto;
                `,
                shouldDisable &&
                  css`
                    opacity: 0.5;
                  `,
              ]}
            />
          )}
        </Component>
      </IndexRowItemContext.Provider>
    );
  }
);

IndexRowItemOptional.displayName = 'IndexRowItem';

const IndexRowItemNamespace = Object.assign(IndexRowItemOptional, {
  Head: IndexRowHead,
  Title: IndexRowTitle,
  Tail: IndexRowTail,
});

export { IndexRowItemNamespace as IndexRowItem };
