/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ShieldIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M14.4142 9.23219C14.8047 8.84167 15.4379 8.84167 15.8284 9.23219C16.2189 9.62272 16.2189 10.2559 15.8284 10.6464L11.5858 14.889C11.1953 15.2796 10.5621 15.2796 10.1716 14.889L8.05025 12.7677C7.65972 12.3772 7.65972 11.744 8.05025 11.3535C8.44077 10.963 9.07394 10.963 9.46446 11.3535L10.8787 12.7677L14.4142 9.23219Z M10.4975 2.18635C11.4362 1.6957 12.5638 1.6957 13.5025 2.18635C14.4144 2.663 15.7011 3.26946 16.7425 3.52981C17.5984 3.74379 18.6064 3.86304 19.4473 3.92762C20.7827 4.03019 22.094 5.01167 22.1742 6.56808C22.2736 8.49728 22.1229 11.8379 20.3741 14.9856C18.4613 18.4287 15.2116 20.9977 13.5863 22.1455C12.6283 22.822 11.3635 22.8148 10.4126 22.1318C8.80731 20.9786 5.61525 18.4199 3.63471 15.0012C2.91102 13.7521 2.50463 12.223 2.27163 10.7909C2.03658 9.34608 1.96704 7.92832 1.95654 6.85785C1.94034 5.20613 3.28908 4.03614 4.76215 3.91066C5.56018 3.84269 6.47353 3.7258 7.25746 3.52981C8.29888 3.26946 9.58559 2.663 10.4975 2.18635ZM12.576 3.95882C12.2177 3.77154 11.7823 3.77154 11.424 3.95882C10.4937 4.44507 9.02936 5.14839 7.74253 5.4701C6.80973 5.7033 5.77649 5.8315 4.9319 5.90344C4.32415 5.95521 3.95216 6.4017 3.95645 6.83824C3.96635 7.84791 4.03223 9.15774 4.24568 10.4697C4.46116 11.7942 4.81632 13.0511 5.36528 13.9987C7.13029 17.0453 10.0432 19.4038 11.5795 20.5074C11.8396 20.6943 12.1727 20.6953 12.4326 20.5118C13.9915 19.4109 16.9372 17.0538 18.6258 14.0143C20.1166 11.3309 20.267 8.42077 20.1768 6.671C20.1592 6.33019 19.8529 5.96467 19.2942 5.92175C18.409 5.85376 17.2727 5.72392 16.2575 5.4701C14.9706 5.14839 13.5063 4.44507 12.576 3.95882Z'
    />
  </Icon>
);
