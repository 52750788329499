import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ButtonStyleProps, ButtonStyleValues } from '../button.type';
import { baseButtonStyle, pressedStyle, hoverStyle } from './common.styles';
import { getStyleValues } from './helpers';

const secondaryButtonTheme: ButtonStyleValues = {
  backgroundColor: 'transparent',
  borderColor: theme.colors.neutral30,
  color: theme.font.colors.default,
  focusRingColor: theme.colors.neutral50,
  hoverColor: theme.colors.neutral10,
  pressed: {
    borderColor: theme.colors.neutral90,
    backgroundColor: theme.colors.neutral10,
  },
  disabled: {
    backgroundColor: 'transparent',
    color: theme.colors.status.disabled,
    secondaryColor: theme.colors.neutral20,
    borderColor: theme.colors.neutral30,
  },
  loading: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.neutral30,
  },
  destructive: {
    pressed: {
      backgroundColor: theme.colors.critical10,
      color: theme.colors.critical70,
      borderColor: theme.colors.critical70,
    },
    color: theme.colors.status.critical,
    focusRingColor: theme.colors.status.critical,
    hoverColor: theme.colors.critical5,
  },
};

export const secondaryStyles = ({ size, isLoading, iconOnly, destructive }: ButtonStyleProps) => {
  const styles = getStyleValues(secondaryButtonTheme, destructive || false);

  const getBorderColor = (c: string) => (iconOnly ? 'transparent' : c);

  return [
    baseButtonStyle({
      focusRingColor: styles.focusRingColor,
      size: size ?? 'small',
      iconOnly,
    }),
    css`
      background: ${styles.backgroundColor};
      border: 1px solid ${getBorderColor(styles.borderColor)};
      color: ${styles.color};
    `,
    isLoading
      ? css`
          border: 1px solid ${getBorderColor(styles.loading.borderColor)};
          background: ${styles.loading.backgroundColor};
          &[aria-disabled='true'] {
            box-shadow: none;
            color: ${styles.disabled.color};
          }
        `
      : css`
          :hover {
            ${hoverStyle({
              hoverColor: styles.hoverColor,
              borderColor: getBorderColor(styles.borderColor),
            })};
          }
          &[aria-disabled='true'] {
            background: ${styles.disabled.backgroundColor};
            border-color: ${getBorderColor(styles.disabled.borderColor)};
            color: ${styles.disabled.color};
          }
          ${pressedStyle({
            pressed: { ...styles.pressed, borderColor: getBorderColor(styles.pressed.borderColor) },
          })}
        `,
  ];
};
