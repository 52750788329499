/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const EditIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 8 8'>
    <path
      fillRule='evenodd'
      d='M4.61292 0.25053C4.85674 -0.00156666 5.23474 -0.0718878 5.55378 0.0783809C6.53226 0.539477 7.38989 1.331 7.90499 2.3061C8.07195 2.62246 8.01559 3.01261 7.76168 3.2684L3.72532 7.33493C3.7253 7.33494 3.72533 7.33492 3.72532 7.33493C3.54687 7.51473 3.31765 7.63644 3.06774 7.68293M3.06774 7.68293L1.47734 7.97876C1.47734 7.97876 1.47734 7.97876 1.47734 7.97876C0.618474 8.13852 -0.120267 7.39346 0.0163754 6.5471C0.016376 6.5471 0.0163747 6.54711 0.0163754 6.5471L0.252333 5.08553C0.293105 4.83307 0.410103 4.59849 0.588429 4.41399L4.61292 0.25053M5.34492 1.79537L1.81458 5.44762L1.67461 6.31463L2.66691 6.13005L6.18705 2.58361C5.95314 2.28038 5.6654 2.0118 5.34492 1.79537ZM2.58967 6.20787C2.58956 6.20798 2.58978 6.20776 2.58967 6.20787ZM1.18473 6.40576C1.18459 6.40578 1.18488 6.40573 1.18473 6.40576Z'
    />
  </Icon>
);
