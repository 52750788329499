import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { styles } from '../../../styles';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../../popover';
import { Text } from '../../text';
import { ActionDropdownChip } from '../atoms';

type BlockTypes = 'paragraph' | 'h1' | 'h2' | 'ul' | 'ol' | 'quote';

type BlockActionProps = {
  value?: BlockTypes;
  onChange: (align: BlockTypes) => void;
} & Pick<React.ComponentProps<typeof ActionDropdownChip>, 'showBoxShadow' | 'compact' | 'css'>;

export const BlockAction = ({
  value = 'paragraph',
  showBoxShadow = true,
  compact = true,
  onChange,
  ...rest
}: BlockActionProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, isOpen } = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>(
    {
      interactionOptions: {
        listNavigation: {
          selectedIndex: selectedIndex(value),
        },
      },
      placement: 'bottom-start',
      middlewareOptions: {
        offset: {
          crossAxis: 0,
          mainAxis: 12,
        },
      },
    }
  );

  const activeBlockItem = useMemo(() => blockItems.find((val) => val.value === value), [value]);
  const activeBlockLabel = activeBlockItem?.label;

  return (
    <>
      <ActionDropdownChip
        showBoxShadow={showBoxShadow}
        isOpen={isOpen}
        isActive={isOpen}
        compact={compact}
        css={css`
          min-width: 100px;
        `}
        {...getTriggerProps()}
        {...rest}
      >
        <span css={styles.truncate}>{activeBlockLabel}</span>
      </ActionDropdownChip>
      <PopoverMenu
        {...getMenuProps()}
        css={css`
          max-width: 160px;
        `}
        returnFocus={false}
      >
        {blockItems.map(({ label, value, size, weight }, index) => (
          <PopoverMenuItem
            key={label}
            {...getItemProps({
              index,
              onClick: () => onChange(value),
            })}
          >
            <Text css={{ fontSize: theme.fontSize(size) }} weight={weight}>
              {label}
            </Text>
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};

const selectedIndex = (block: string) => {
  const index = blockItems.findIndex((alignObj) => alignObj.value === block);
  return index < 0 ? null : index;
};

const blockItems = [
  {
    label: 'Header 1',
    value: 'h1',
    size: 24,
    weight: 'bold',
  },
  {
    label: 'Header 2',
    value: 'h2',
    size: 16,
    weight: 'bold',
  },
  {
    label: 'Paragraph',
    value: 'paragraph',
    size: 14,
    weight: 'regular',
  },
  {
    label: 'Bullet List',
    value: 'ul',
    size: 14,
    weight: 'regular',
  },
  {
    label: 'Ordered List',
    value: 'ol',
    size: 14,
    weight: 'regular',
  },
  {
    label: 'Quote',
    value: 'quote',
    size: 14,
    weight: 'regular',
  },
] as const;
