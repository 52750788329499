/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const HistoryIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M5.25319 3.7657C6.03352 3.27431 6.95834 2.9904 7.95068 2.9904C10.7369 2.9904 12.9956 5.23328 12.9956 8.00001C12.9956 10.7667 10.7369 13.0096 7.95068 13.0096C5.47734 13.0096 3.41785 11.2412 2.98826 8.90795C2.88871 8.36727 2.36662 8.00911 1.82214 8.10796C1.27765 8.20682 0.916968 8.72525 1.01652 9.26592C1.61707 12.5277 4.49207 15 7.95068 15C11.8439 15 15 11.866 15 8.00001C15 4.13401 11.8439 1 7.95068 1C6.43254 1 5.02577 1.47727 3.87542 2.28775V2.02847C3.87542 1.47884 3.42671 1.03327 2.87321 1.03327C2.31969 1.03327 1.87099 1.47883 1.87099 2.02847V4.5121C1.87099 5.19914 2.43187 5.7561 3.12376 5.7561H5.62488C6.17839 5.7561 6.62709 5.31053 6.62709 4.7609C6.62709 4.21126 6.17839 3.7657 5.62488 3.7657H5.25319ZM7.5134 5.80204C7.92853 5.80204 8.26506 6.13621 8.26506 6.54844V8.24631L9.55007 8.69667C9.94154 8.83386 10.1468 9.26021 10.0087 9.6489C9.87048 10.0376 9.44115 10.2415 9.04969 10.1044L7.43039 9.53684C7.0297 9.39642 6.76174 9.02031 6.76174 8.59837V6.54844C6.76174 6.13621 7.09827 5.80204 7.5134 5.80204Z'
    />
  </Icon>
);
