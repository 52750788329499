import { ButtonHTMLAttributes, ReactNode, MouseEvent } from 'react';
import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  id: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  trackingId?: string;
};

export const Tab = ({ children, ...rest }: Props) => {
  const styles = useStyles('TabFilter', 'tabItem');
  return (
    <NakedButton css={styles} {...rest}>
      {children}
    </NakedButton>
  );
};
