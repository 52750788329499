import React from 'react';
import { Icon } from '@frontend/icons';
import { styles } from '../../../styles';
import { Text } from '../../text';
import { ActionPressedChip } from '../atoms';

type UnderlineActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const UnderlineAction = ({ compact = true, showBoxShadow = true, ...rest }: UnderlineActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <Text as='span' css={styles.flexCenter}>
        <Icon name='underline-small' />
      </Text>
    </ActionPressedChip>
  );
};
