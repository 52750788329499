import React, { useEffect, type ComponentProps } from 'react';
import { useFade } from '../../../../hooks/animations/use-fade';
import { ListboxChildren } from '../../../listbox/listbox.provider';
import { FieldLayout } from '../../layouts/field-layout.component';
import { BasicFormFieldProps, ExtraFieldProps } from '../../layouts/types';
import { activeDropdownStyle } from '../../list-fields/select-list/old-styles';
import { useDropdownContext } from '../../providers/dropdown-provider';
import { DropdownIcon } from '../dropdown/dropdown-icon.component';
import { MultiselectInput } from './multiselect-input';
import { MultiselectMenu } from './multiselect-menu';

export type MultiselectFieldProps = BasicFormFieldProps<'multiselect'> &
  Pick<ExtraFieldProps, 'containerCss'> & {
    children: ListboxChildren;
    searchFieldPlaceholder?: string;
  };

export const MultiselectBaseField = ({
  children,
  maxAllowed,
  placeholder = 'Select one',
  direction = 'down',
  autoClose,
  ...rest
}: MultiselectFieldProps) => {
  const { active, setActive } = useDropdownContext();
  const menuStyle = useFade(active);

  useEffect(() => {
    if (autoClose && maxAllowed === rest.value.length) {
      // note: the onBlur function is different between useForm and useFormField.
      // useForm requires an event to be passed in, but useFormField does not.
      // this component is used by both hooks.
      rest.onBlur({
        target: { name: rest.name },
      } as React.FocusEvent<HTMLInputElement>);
      setActive(false);
    }
  }, [rest.value.length]);

  return (
    <FieldLayout
      // the input takes vs what we expose via API in the field.
      // The problem is the props are shared for both instances
      // and probably shouldn't be.
      //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
      field={MultiselectInput as ComponentProps<typeof FieldLayout>['field']}
      fieldComponentProps={{
        options: children,
      }}
      endAdornment={<DropdownIcon active={active} />}
      css={active && activeDropdownStyle('bottom')}
      placeholder={placeholder}
      {...rest}
    >
      <MultiselectMenu
        active={active}
        direction={direction}
        style={menuStyle}
        name={rest.name}
        placeholder={rest.searchFieldPlaceholder}
      >
        {children}
      </MultiselectMenu>
    </FieldLayout>
  );
};
