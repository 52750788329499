import { useMemo, useReducer, useRef, useCallback, Reducer, useEffect } from 'react';
import { isFunction } from 'lodash-es';
import { genUID } from '../../../../helpers';
import type {
  UseFormProps,
  FieldStateReducer,
  FocusEvent,
  FormActions,
  FormState,
  FormConfig,
  FormValues,
  ObjectFromFormConfig,
  ResolvedFieldProps,
} from '../types';
import { FormFieldActionTypes } from '../types';
import {
  initializeFieldState,
  normalizeChangeHandler,
  validateField,
  formIsComplete,
  getFieldErrors,
  getFieldValues,
  getError,
  trimIfString,
  diffValues,
} from '../utils';
import { setValue } from '../value-setters';

const hasSecondValidationArg = (field) => isFunction(field.validator) && field.validator.length > 1;

type StateReducer<T extends FormConfig> = Reducer<FormState<T>, FormActions<T>>;

export const reducer =
  <T extends FormConfig>(fieldStateReducer: FieldStateReducer<T> = () => null): StateReducer<T> =>
  (state, action) => {
    switch (action.type) {
      case FormFieldActionTypes.Blur: {
        const { name } = action.payload;
        const nextFieldState = {
          ...state[name],
          active: false,
          touched: true,
          value: trimIfString(state[name]?.value),
        };
        const next = {
          ...state,
          [name]: {
            ...nextFieldState,
            ...validateField(nextFieldState, state),
          },
        };
        return Object.assign(next, fieldStateReducer(next, action));
      }
      case FormFieldActionTypes.Focus: {
        const { name } = action.payload;
        const next = { ...state, [name]: { ...state[name], active: true } };
        return Object.assign(next, fieldStateReducer(next, action));
      }
      case FormFieldActionTypes.Reset:
        return initializeState(action.payload);
      case FormFieldActionTypes.Seed: {
        // don't seed unless some value is changing
        // (used via effects, so will run at least twice by default)
        const needsToSeed = Object.entries(action.payload).some(([name, value]) => value !== state[name]?.value);
        if (!needsToSeed) return state;

        let needsSecondPass = false;
        const nextState = Object.entries(state).reduce((obj, [name, field]) => {
          // track if we have any custom validators expecting the second arg
          if (!needsSecondPass && hasSecondValidationArg(field)) {
            needsSecondPass = true;
          }
          if (action.payload.hasOwnProperty(name)) {
            const next = setValue(field, action.payload[name]);
            return {
              ...obj,
              [name]: { ...next, ...validateField(next, state), touched: true },
            };
          }
          return { ...obj, [name]: field };
        }, {} as FormState<T>);

        if (needsSecondPass) {
          // need to do a double pass if any custom validators expecting state
          // (don't have the complete updated state in the reducer above)
          return Object.entries(nextState).reduce(
            (obj, [name, field]) => ({
              ...obj,
              [name]: { ...field, ...validateField(field, nextState) },
            }),
            {} as FormState<T>
          );
        }
        return nextState;
      }
      case FormFieldActionTypes.Update: {
        const { name, value } = action.payload;
        const shouldValidateOnChange = state[name]?.validateOnChange ? { touched: true } : {};
        const fieldState = setValue({ ...state[name], ...shouldValidateOnChange }, value);
        const next = {
          ...state,
          [name]: {
            ...fieldState,
            ...(fieldState.touched ? validateField(fieldState, state) : { error: getError(fieldState, state) }),
          },
        };
        return Object.assign(next, fieldStateReducer(next, action));
      }
      case FormFieldActionTypes.Validate:
        return Object.entries(state).reduce((obj, [name, data]) => {
          const nextData = { ...data, touched: true };
          return { ...obj, [name]: { ...nextData, ...validateField(nextData, state) } };
        }, {} as FormState<T>);
      default:
        return state;
    }
  };

export const initializeState = <T extends FormConfig, K extends keyof T>(fields: T): FormState<T> => {
  let needsSecondPass = false;
  const state = Object.entries(fields).reduce((obj, [name, data]) => {
    if (!needsSecondPass && hasSecondValidationArg(data)) {
      needsSecondPass = true;
    }
    return {
      ...obj,
      [name]: { ...initializeFieldState(data as T[K]), name },
    };
  }, {} as FormState<T>);

  if (needsSecondPass) {
    // need to do a double pass if any custom validators expecting state
    return Object.entries(state).reduce(
      (obj, [name, field]) => ({
        ...obj,
        [name]: { ...field, ...validateField(field, state) },
      }),
      {} as FormState<T>
    );
  }
  return state;
};

const genIds = <T extends FormConfig>(fields: T): ObjectFromFormConfig<T, string> =>
  Object.keys(fields).reduce(
    (obj, key) => ({ ...obj, [key]: `field-${genUID()}` }),
    {} as ObjectFromFormConfig<T, string>
  );

export type FieldProps<T extends FormConfig, G extends keyof T> = {
  name: G;
} & ResolvedFieldProps<T[G]['type']>;
export type UseFormReturnType<T extends FormConfig, K extends keyof T> = {
  formProps: {
    ref: import('react').MutableRefObject<HTMLFormElement | null>;
    noValidate: boolean;
    onSubmit: (event: React.FormEvent) => void;
  };
  getErrors: () => ObjectFromFormConfig<T, string>;
  getFieldProps: <G extends K>(name: G) => FieldProps<T, G>;
  isComplete: boolean;
  reset: (newFields?: T) => void;
  seedValues: (values: FormValues<T>) => void;
  validate: () => void;
  changedValues: FormValues<T> | null | undefined;
  values: FormValues<T>;
};

/**
 * Hook for controlling all field state for an entire form.
 * Use whenever you have multiple fields that should be submitted together.
 * Can handle submission logic nicely. On submit of incomplete form,
 * the hook will show error messages for all invalid fields
 * and shift focus to the first invalid field.
 * Submission logic requires a `form` element as a parent of all fields.
 * @param {boolean} [props.allowInvalidSubmission] Bypass submit logic when needed.
 * @param {Object} props.fields Object of field names + field configs (props depending on `validator` type)
 * @param {Function} [props.fieldStateReducer] Function for controlling relations between fields when needed. Passes the next state and the action that was fired. Must be a pure function (no references to external values and returns new state, no mutation.)
 * @param {Function} [props.onSubmit] Function to run on valid submission. Omit if not using a `form` as parent.
 */
export function useForm<T extends FormConfig, K extends keyof T>({
  allowInvalidSubmission,
  computeChangedValues,
  fields,
  fieldStateReducer,
  onSubmit,
}: UseFormProps<T>): UseFormReturnType<T, K> {
  const formRef = useRef<HTMLFormElement | null>(null);
  const idsMap = useRef<ObjectFromFormConfig<T, string>>(genIds(fields));
  const [state, dispatch] = useReducer(reducer(fieldStateReducer), fields, initializeState);
  // track initial values for computing changes
  const initialValuesRef = useRef<FormValues<T> | undefined>();

  useEffect(() => {
    initialValuesRef.current = getFieldValues(state);
  }, []);
  // The onBlur, onFocus and onChange handlers from useForm and useFormField
  // are stable values and can be left out of effects dependency arrays.
  // They will trigger effects as new values because they are returned via spread
  // to mix in with other frequently changing field + form values.
  const { seedValues, ...handlers } = useMemo(
    () => ({
      onBlur: (event: FocusEvent) => {
        dispatch({
          type: FormFieldActionTypes.Blur,
          payload: { name: event?.target.name },
        });
      },
      onChange: normalizeChangeHandler((payload) => {
        dispatch({ type: FormFieldActionTypes.Update, payload });
      }),
      onFocus: (event: FocusEvent) => {
        dispatch({
          type: FormFieldActionTypes.Focus,
          payload: { name: event?.target.name },
        });
      },
      seedValues: (values: FormValues<T>) => {
        initialValuesRef.current = values;
        dispatch({ type: FormFieldActionTypes.Seed, payload: values });
      },
    }),
    []
  );

  const focusFirstInvalid = () => {
    setTimeout(() => {
      if (formRef.current) {
        const firstInvalid: HTMLElement | null = formRef.current.querySelector('[aria-invalid="true"]');
        if (firstInvalid) firstInvalid.focus();
      }
    }, 100);
  };

  const validate = useCallback(() => {
    dispatch({ type: FormFieldActionTypes.Validate });
    focusFirstInvalid();
  }, []);

  const changedValues: FormValues<T> | null =
    computeChangedValues && initialValuesRef.current
      ? diffValues({ current: getFieldValues(state), initial: initialValuesRef.current })
      : null;
  const isComplete = formIsComplete(state);
  const values: FormValues<T> = getFieldValues(state);

  return {
    formProps: {
      ref: formRef,
      noValidate: true,
      onSubmit: (event: React.FormEvent) => {
        event.preventDefault();

        if (isFunction(onSubmit)) {
          if (allowInvalidSubmission || isComplete) {
            onSubmit(values, changedValues);
          } else {
            validate();
          }
        }
      },
    },
    getErrors: () => getFieldErrors(state),
    getFieldProps: <G extends K>(name: G) => {
      const { type, validator, validateOnChange, ...rest } = state[name] || {};

      const field: unknown = {
        id: idsMap.current[name],
        name,
        ...rest,
        ...handlers,
      };
      return field as { name: G } & ResolvedFieldProps<T[G]['type']>;
    },
    isComplete,
    reset: (newFields) => {
      if (computeChangedValues) {
        initialValuesRef.current = getFieldValues(initializeState(newFields ?? fields));
      }
      dispatch({ type: FormFieldActionTypes.Reset, payload: newFields ?? fields });
    },
    seedValues,
    validate,
    changedValues: computeChangedValues ? changedValues : undefined,
    values,
  };
}
