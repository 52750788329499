import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { NakedUl } from '../../../naked';
import { Text } from '../../text';
import { useTemplateSettings, useTemplateTags } from '../message-template.context';
import { validateTags } from '../parse-template';
import { TagType } from '../types';
import { MessageTemplateTag } from './message-template-tag';

type TagListProps = HTMLAttributes<HTMLUListElement> & {
  onTagSelect?: (event: React.MouseEvent<HTMLElement, MouseEvent>, tag: TagType) => void;
  helperText?: ReactNode;
  showTagsOfType?: 'ALL' | 'REQUIRED' | 'OPTIONAL';
};

export const TagList = ({ onTagSelect, showTagsOfType = 'ALL', helperText, ...props }: TagListProps) => {
  const { addTagToTemplate, tags, isNewTag, editorValue, correspondingTags, sharedTags, setSharedTags } =
    useTemplateTags();
  const { readOnly } = useTemplateSettings();

  if (process.env.NODE_ENV === 'development') {
    validateTags(tags, isNewTag);
  }

  const tagsToRender = useMemo<TagType[]>(() => {
    if (showTagsOfType === 'REQUIRED') return tags.filter((tag) => tag.optional === false);
    if (showTagsOfType === 'OPTIONAL') return tags.filter((tag) => tag.optional !== false);
    return tags;
  }, [showTagsOfType, tags]);

  return (
    <>
      {!!helperText && (
        <Text
          size='small'
          css={{
            padding: theme.spacing(0.5),
            lineHeight: theme.fontSize(16),
          }}
          color={'light'}
        >
          {helperText}
        </Text>
      )}

      {tags?.length > 0 && (
        <NakedUl
          css={css`
            display: flex;
            flex-wrap: wrap;
            margin: 0;
          `}
          {...props}
        >
          {tagsToRender.map((tag, idx) => (
            <li key={`${tag.label}-${tag.key}-${idx}`} css={css({ margin: 4 })}>
              <MessageTemplateTag
                tabIndex={0}
                tag={tag}
                onTagSelect={onTagSelect}
                addTagToTemplate={addTagToTemplate}
                readOnly={readOnly || tag?.readOnly}
                editorValue={editorValue}
                correspondingTags={correspondingTags}
                sharedTags={sharedTags}
                setSharedTags={setSharedTags}
              />
            </li>
          ))}
        </NakedUl>
      )}
    </>
  );
};
