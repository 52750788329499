import React from 'react';
import { Icon } from '@frontend/icons';
import { styles } from '../../../styles';
import { Text } from '../../text';
import { ActionPressedChip } from '../atoms';

type ItalicActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const ItalicAction = ({ compact = true, showBoxShadow = true, ...rest }: ItalicActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <Text as='em' weight='bold' css={styles.flexCenter}>
        <Icon name='italic-small' />
      </Text>
    </ActionPressedChip>
  );
};
