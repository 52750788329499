import React, { useRef } from 'react';
import { css } from '@emotion/react';
import { Cell, ColumnInstance, Row, TableInstance } from 'react-table';
import { useThemeValues } from '../../../../hooks';
import { useStyles } from '../../../../use-styles';
import { Text } from '../../../text';
import { setFocusableElements } from '../../hooks';
import { focusCell } from '../../hooks/utils';
import { TableData } from '../../table-data-type';
import { CellPositionCord, EmotionStyle, TableRowActions, TableStyleConfig } from '../../table-types';
import { getCellStyles, isReservedCellId } from '../../utils';
import { TableLoadingSkeleton } from '../table-loading-skeleton.component';
import { DefaultCell } from './default-cell.component';
import { displayCellType, reservedCells } from './';

const getCellPositionProp = ({ tableId, x, y }: CellPositionCord) => ({
  'table-cell-position': `${tableId}-${x}-${y}`,
});

type TableCellProps<T extends TableData> = {
  cell: Cell<T>;
  column: ColumnInstance<T>;
  onRowClick?: TableRowActions<T>['onRowClick'];
  onRowSelect?: TableRowActions<T>['onRowSelect'];
  row: Row<T>;
  cellPosition: CellPositionCord;
  currentCellPositionRef: React.MutableRefObject<CellPositionCord>;
  isFocusable: boolean;
  isLoading: boolean;
  tableId: string;
  tableClientWidth: number;
  tableInstance: TableInstance<T>;
  hasExpandableRow: boolean;
  handleExpandRow: () => void;
  isExpandableRowDisabled: boolean | undefined;
  cellTrackingId?: string;
};

export const TableCell = <T extends TableData>({
  cell,
  column,
  onRowClick,
  onRowSelect,
  row,
  cellPosition,
  currentCellPositionRef,
  isFocusable,
  isLoading,
  tableId,
  tableClientWidth,
  hasExpandableRow,
  handleExpandRow,
  tableInstance,
  isExpandableRowDisabled,
  cellTrackingId,
}: TableCellProps<T>) => {
  const { isMobile, tableTrackingIds, rowSelectionConfig } = tableInstance;
  const { id: columnId, styleConfig = {}, cellAlign, mobileLayoutConfig } = column;
  const isReservedCell = isReservedCellId(column.id);
  const { spacing } = useThemeValues();
  const tableCellStyles = useStyles('TableCommonStyles', 'tableCellStyles', { isMobile, isDisabled: row.isDisabled });
  const cellRef = useRef<HTMLDivElement | null>(null);

  const { key, ...cellProps } = cell.getCellProps();

  const { columns: columnStyles = [], rows: rowStyles } = styleConfig as TableStyleConfig<T, string>;

  const componentProps = {
    cellValue: cell.value,
    cellRenderer: column.cellRenderer,
    column,
    cellConfig: column.cellConfig,
    cellAlign,
    rowData: row.original,
    rowObject: row,
    cellPosition,
    currentCellPositionRef,
    tableClientWidth: tableClientWidth,
    isMobile,
    tableTrackingIds: tableTrackingIds,
    tableInstance,
    isSingleSelect: rowSelectionConfig?.isSingleSelect,
    isExpandableRowDisabled,
    onRowSelect,
  };

  const columnUniqueId = column.id as keyof typeof displayCellType;
  const CellComponent = displayCellType[columnUniqueId] || DefaultCell;

  const cellStyles = getCellStyles({
    cellValue: cell.value,
    columnId,
    columnStyles,
    column,
    rowObject: row,
    isMobile,
  });

  const rowStyling = () => {
    const { original: rowData, index } = row;
    if (typeof rowStyles === 'function') {
      return rowStyles?.(rowData, index, row, isMobile);
    }
    return rowStyles;
  };

  const handleCellClick = (e) => {
    if (columnUniqueId === reservedCells.ROW_EXPAND) {
      handleExpandRow();
      e.stopPropagation();
    }
    if (onRowClick || hasExpandableRow) {
      const cellElem = cellRef?.current;
      if (cellElem?.querySelector('input') || cellElem?.querySelector('button')) {
        e.stopPropagation();
      }
    }
    if (isFocusable) {
      focusCell(cellPosition, currentCellPositionRef, true);
    }
  };

  const cellComposedStyles = [
    tableCellStyles,
    css`
      ${column.maxWidth ? `max-width: ${column.maxWidth}px;` : null}
    `,
    cellStyles,
    rowStyling(),
    isMobile &&
      !isReservedCell &&
      css`
        /*
        To override all custom widths set by developers, these CSS properties
        need to be set to !important. When the layout is mobile, we want all
        of these properties to have a value of 50% at all times
        */
        min-width: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
      `,
    isMobile &&
      isReservedCell &&
      css`
        border-bottom: none !important;
        background-color: transparent;
      `,
    isMobile &&
      column.id === reservedCells.SELECT_ROW &&
      css`
        margin-right: auto;
      `,
  ];

  const cellPositionProp = getCellPositionProp(cellPosition);

  const Component = isMobile && !isReservedCell ? 'div' : React.Fragment;

  const conditionalFlex =
    isMobile && !isReservedCell
      ? {
          css: [
            css`
              display: flex;
              flex-direction: column;
              width: 100%;
              justify-content: ${mobileLayoutConfig?.hideHeader ? 'center' : 'space-between'};
              padding: ${spacing(1)};
              gap: ${spacing(0.5)};
            `,
            cellStyles,
            rowStyling(),
            mobileLayoutConfig?.spanFullWidth &&
              css`
                grid-column: span 2;
              `,
            mobileLayoutConfig?.order &&
              css`
                order: ${mobileLayoutConfig?.order};
              `,
            mobileLayoutConfig?.leftShift && {
              marginLeft: -mobileLayoutConfig?.leftShift,
            },
          ] as EmotionStyle,
          className: `cell__${tableId} table__cell ${row.isSelected ? 'selected' : row.isExpanded ? 'expanded' : ''}`,
        }
      : {};

  return (
    <Component {...conditionalFlex}>
      {!mobileLayoutConfig?.hideHeader && isMobile && !isReservedCell && (
        <Text
          size='small'
          color={row.isDisabled ? 'disabled' : 'light'}
          css={css`
            margin: 0;
          `}
        >
          {isMobile && !isReservedCell && cell.column.render('Header')}
        </Text>
      )}
      <div
        onClick={handleCellClick}
        key={key}
        {...cellProps}
        {...cellPositionProp}
        className={`cell__${tableId} table__cell ${row.isSelected ? 'selected' : row.isExpanded ? 'expanded' : ''} ${
          column.id === reservedCells.ACTIONS && 'hideforprint'
        }`}
        ref={(node) => {
          if (node) {
            cellRef.current = node;

            setFocusableElements(node, cellPosition, currentCellPositionRef);
          }
        }}
        css={cellComposedStyles}
        role='cell'
        data-trackingid={cellTrackingId}
      >
        {!isLoading ? (
          <CellComponent {...componentProps} />
        ) : (
          <TableLoadingSkeleton fullWidth={isReservedCell ? true : false} />
        )}
      </div>
    </Component>
  );
};
