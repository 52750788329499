import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import type { ButtonSize, ButtonStyleValues } from '../button.type';

// This function is used for all the buttons types.
// The circular buttons don't have a `size` prop, so when no `size` is passed
// we add styles for circular buttons.
export const buttonFocusStyle = (styleValues: Pick<ButtonStyleValues, 'focusRingColor'>) => css`
  position: relative;

  :focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px white, 0 0 0 4px ${styleValues.focusRingColor};
  }

  :focus:not(:active)::before,
  :focus-within:not(:active)::before {
    opacity: 1;
  }
`;

const sizes = {
  large: {
    lineHeight: 1.375,
    fontSize: 16,
    height: 40,
  },
  small: {
    lineHeight: 1.375,
    fontSize: 14,
    height: 32,
  },
} as const;

type BaseButtonStyle = Pick<ButtonStyleValues, 'focusRingColor'> & {
  size: ButtonSize;
  iconOnly: boolean;
};

export const baseButtonStyle = ({ size, focusRingColor, iconOnly }: BaseButtonStyle) => css`
  align-items: center;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  display: inline-flex;
  font-family: ${theme.font.family};
  font-size: ${theme.fontSize(sizes[size].fontSize)};
  font-weight: ${theme.font.weight.bold};
  height: ${sizes[size].height}px;
  justify-content: center;
  line-height: ${sizes[size].lineHeight};
  position: relative;
  padding: ${iconOnly ? '8px' : '8px 12px'};
  text-transform: capitalize;
  text-wrap: nowrap;
  transition: background 150ms ease-out, color 150ms ease-out, border-color 150ms ease-out;
  width: auto;
  ${buttonFocusStyle({ focusRingColor })};
  &[aria-disabled='true'] {
    cursor: default;
  }
`;

export const hoverStyle = ({
  hoverColor,
  borderColor,
}: Partial<Pick<ButtonStyleValues, 'hoverColor' | 'borderColor'>>) => css`
  background: ${hoverColor};
  border-color: ${borderColor};
`;

export const pressedStyle = ({ pressed }: Pick<ButtonStyleValues, 'pressed'>) => css`
  :active:not([aria-disabled='true']) {
    background: ${pressed.backgroundColor};
    border-color: ${pressed.borderColor};
    color: ${pressed.color};
    svg {
      color: ${pressed.color};
    }
  }
`;
