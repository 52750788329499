/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const RestoreIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M14.1715729,1.75 C14.961697,1.75 15.7221565,2.03771929 16.3128777,2.55513748 L16.4696699,2.70190296 L19.298097,5.53033009 C19.8567992,6.08903221 20.191077,6.83020653 20.2429101,7.61377945 L20.25,7.82842712 L20.25,14 C20.25,14.6903559 19.6903559,15.25 19,15.25 C18.3527913,15.25 17.8204661,14.7581253 17.7564536,14.1278052 L17.75,14 L17.75,9.75 L15.5,9.75 C13.7669685,9.75 12.3507541,8.39354523 12.2551448,6.68442391 L12.25,6.5 L12.25,4.25 L6.25,4.25 L6.25,19.75 L12,19.75 C12.6903559,19.75 13.25,20.3096441 13.25,21 C13.25,21.6472087 12.7581253,22.1795339 12.1278052,22.2435464 L12,22.25 L6,22.25 C4.80913601,22.25 3.83435508,21.3248384 3.75519081,20.1540488 L3.75,20 L3.75,4 C3.75,2.80913601 4.67516159,1.83435508 5.84595119,1.75519081 L6,1.75 L14.1715729,1.75 Z M15.25,11.5 C15.9403559,11.5 16.5,12.0596441 16.5,12.75 C16.5,13.4403559 15.9403559,14 15.25,14 L13.764,14 L18.2063517,18.4431432 C18.6945071,18.9312986 18.6945071,19.7227548 18.2063517,20.2109102 C17.7181964,20.6990655 16.9267401,20.6990655 16.4385848,20.2109102 L12,15.772 L12,17.25 C12,17.9403559 11.4403559,18.5 10.75,18.5 C10.0596441,18.5 9.5,17.9403559 9.5,17.25 L9.5,12.75 C9.5,12.0596441 10.0596441,11.5 10.75,11.5 L15.25,11.5 Z M14.75,4.518 L14.75,6.5 C14.75,6.87969577 15.0321539,7.19349096 15.3982294,7.24315338 L15.5,7.25 L17.482,7.25 L14.75,4.518 Z'
    />
  </Icon>
);
