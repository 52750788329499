import React from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { styles } from '../../../styles';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../../popover';
import { ActionDropdownChip } from '../atoms';

type FontPickerActionProps = {
  value?: string;
  onChange: (font: string) => void;
} & Pick<React.ComponentProps<typeof ActionDropdownChip>, 'showBoxShadow' | 'compact' | 'css'>;

export const FontPickerAction = ({
  value = 'DM Sans',
  showBoxShadow = true,
  compact,
  onChange,
  ...rest
}: FontPickerActionProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, isOpen } = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>(
    {
      interactionOptions: {
        listNavigation: {
          selectedIndex: selectedIndex(value),
        },
      },
      placement: 'bottom-start',
      middlewareOptions: {
        offset: {
          crossAxis: 0,
          mainAxis: 12,
        },
      },
    }
  );

  return (
    <>
      <ActionDropdownChip
        showBoxShadow={showBoxShadow}
        isOpen={isOpen}
        isActive={isOpen}
        compact={compact}
        {...getTriggerProps()}
        {...rest}
        css={css`
          width: 140px;
        `}
      >
        <span
          css={[
            styles.truncate,
            css`
              color: ${theme.colors.neutral90};
            `,
          ]}
        >
          {value}
        </span>
      </ActionDropdownChip>
      <PopoverMenu
        {...getMenuProps()}
        returnFocus={false}
        css={css`
          width: 291px;
        `}
      >
        {defaultFonts.map((value, index) => (
          <PopoverMenuItem
            key={value}
            {...getItemProps({
              index,
              onClick: () => onChange(value),
            })}
            css={{
              span: {
                fontFamily: value,
              },
            }}
          >
            {value}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};

const selectedIndex = (selectedFont: string) => {
  const index = defaultFonts.indexOf(selectedFont);
  return index < 0 ? null : index;
};

const defaultFonts = [
  'DM Sans',
  'Arial',
  'Helvetica',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Georgia',
  'Times New Roman',
  'Courier New',
];
