import { HTMLAttributes, ReactNode } from 'react';
import { css } from '@emotion/react';
import { mergeClasses } from '../../../helpers';
import { useThemeValues } from '../../../hooks';
import { animated } from '../../animated';
import { ContentLoader } from '../../loader';
import { useModalContext } from '../provider';

export type ModalBoxProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  flex?: boolean;
  maxWidth?: number;
  minWidth?: number;
};

const maxWidthIncludingMargins = '100% - 48px';

export const ModalBox = ({ children, className, flex, minWidth = 0, maxWidth = 0, ...rest }: ModalBoxProps) => {
  const { containerProps, loadingState } = useModalContext();
  const { spacing, colors, borderRadius } = useThemeValues();
  return (
    <animated.section
      css={[
        css`
          width: ${maxWidth ? '100%' : 'unset'};
          min-width: ${minWidth > 0 ? `min(${minWidth}px, ${maxWidthIncludingMargins})` : 0};
          max-width: ${maxWidth
            ? `min(${maxWidth}px, ${maxWidthIncludingMargins})`
            : `calc(${maxWidthIncludingMargins})`};
          max-height: calc(100% - ${spacing(8)});
          background: ${colors.white};
          border-radius: ${borderRadius.medium};
          position: relative;
        `,
        flex
          ? css`
              display: flex;
              flex-direction: column;
            `
          : css`
              overflow-y: auto;
            `,
        maxWidth > 0 &&
          css`
            @media only screen and (min-width: ${maxWidth + 48}px) {
              width: auto;
            }
          `,
      ]}
      className={mergeClasses('wv-modal-box', className)}
      onClick={(e) => e.stopPropagation()}
      {...rest}
      {...containerProps}
    >
      {children}
      <ContentLoader
        css={css`
          border-radius: ${borderRadius.medium};
        `}
        {...loadingState}
      />
    </animated.section>
  );
};
