import { ListboxOption } from '../../../listbox';
import type { DropdownFieldProps } from './types';
import { DropdownProvider } from '../../providers/dropdown-provider';
import { DropdownSearchBaseField, DropdownSearchFieldConfigType } from './dropdown-search-base-field';

export type DropdownSearchFieldProps = DropdownFieldProps & DropdownSearchFieldConfigType;

/**
 *
 * @deprecated There are no known instances of this component throughout weave apps.
 * Its UX is subpar to other dropdowns, and its use is discouraged
 */
export const DropdownSearchField = ({ children, ...rest }: DropdownSearchFieldProps) => (
  <DropdownProvider
    id={rest.id}
    onBlur={rest.onBlur}
    onFocus={rest.onFocus}
    onChange={rest.onChange}
    value={rest.value}
    name={rest.name}
  >
    <DropdownSearchBaseField {...rest}>{children}</DropdownSearchBaseField>
  </DropdownProvider>
);

DropdownSearchField.Option = ListboxOption;
