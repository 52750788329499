/**
 * This function is only to be used for converting React Quill nested lists to the DS version
 */
export const convertQuillToNestedHTML = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  function processListItems(items: HTMLLIElement[]): string {
    let result = '';
    let prevIndent = -1;
    const listStack: string[] = [];

    function getListType(item: HTMLLIElement): string {
      const parent = item.parentNode;
      if (parent instanceof HTMLElement) {
        return parent.tagName.toLowerCase();
      }
      return 'ul';
    }

    function openList(type: string, indent: number): void {
      while (listStack.length <= indent) {
        result += `<${type}>`;
        listStack.push(type);
      }
    }

    function closeLists(toIndent: number): void {
      while (listStack.length > toIndent) {
        result += `</${listStack.pop()}>`;
      }
    }

    for (const item of items) {
      const indentMatch = item.className.match(/ql-indent-(\d+)/);
      const indent = indentMatch ? parseInt(indentMatch[1]) : 0;
      const listType = getListType(item);

      if (indent > prevIndent) {
        openList(listType, indent);
      } else if (indent < prevIndent) {
        closeLists(indent);
      }

      result += `<li>${item.innerHTML}</li>`;
      prevIndent = indent;
    }

    closeLists(0);
    return result;
  }

  let result = '';
  let currentListItems: HTMLLIElement[] = [];

  function processCurrentList(): void {
    if (currentListItems.length > 0) {
      result += processListItems(currentListItems);
      currentListItems = [];
    }
  }

  for (const node of Array.from(doc.body.childNodes)) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const elementNode = node as HTMLElement;
      if (elementNode.tagName === 'OL' || elementNode.tagName === 'UL') {
        currentListItems = currentListItems.concat(Array.from(elementNode.children) as HTMLLIElement[]);
      } else {
        processCurrentList();
        result += elementNode.outerHTML;
      }
    } else if (node.nodeType === Node.TEXT_NODE && node.textContent && node.textContent.trim() !== '') {
      processCurrentList();
      result += `<p>${node.textContent}</p>`;
    }
  }

  processCurrentList();

  return result;
};
