import { KeyNames } from '../../../constants';

export const getKeyboardHandler = (selector: string) => (e: React.KeyboardEvent) => {
  const target = e.target as HTMLButtonElement;
  const tabs: HTMLButtonElement[] = Array.from(target.parentElement!.querySelectorAll(selector));
  const tabCount = tabs.length;

  switch (e.key) {
    case KeyNames.Right: {
      if (target.nextSibling) {
        (target.nextSibling as HTMLButtonElement).focus();
      } else {
        tabs[0].focus();
      }
      break;
    }
    case KeyNames.Left: {
      if (target.previousSibling) {
        (target.previousSibling as HTMLButtonElement).focus();
      } else {
        tabs[tabCount - 1].focus();
      }
      break;
    }
    case KeyNames.Home:
      tabs[0].focus();
      break;
    case KeyNames.End:
      tabs[tabCount - 1].focus();
      break;
    default:
      break;
  }
};
