import { Icon, IconProps } from '../Icon.component';

export const GridIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      d='M10 3H3V10H10V3Z'
      fill='none'
      stroke='#202328'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21 3H14V10H21V3Z'
      fill='none'
      stroke='#202328'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21 14H14V21H21V14Z'
      fill='none'
      stroke='#202328'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 14H3V21H10V14Z'
      fill='none'
      stroke='#202328'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
