/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const AlertIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3.44444444 C7.27489714,3.44444444 3.44444444,7.27489714 3.44444444,12 C3.44444444,16.7251029 7.27489714,20.5555556 12,20.5555556 C16.7251029,20.5555556 20.5555556,16.7251029 20.5555556,12 C20.5555556,7.27489714 16.7251029,3.44444444 12,3.44444444 Z M11.9456522,13.6818182 C12.5925272,13.6818182 13.1251444,14.1733234 13.1891947,14.8035315 L13.1956522,14.931318 C13.1956522,15.5793897 12.7034647,16.1113994 12.0733988,16.1753691 L11.9456522,16.1818182 C11.2546522,16.1818182 10.6956522,15.6225945 10.6956522,14.931318 C10.6956522,14.2841841 11.1869608,13.7522331 11.8177408,13.6882671 L11.9456522,13.6818182 Z M11.9457391,6.97659348 C12.6360951,6.97659348 13.1957391,7.46324049 13.1957391,8.06355 L13.1957391,8.06355 L13.1957391,11.86355 C13.1957391,12.4638595 12.6360951,12.9505065 11.9457391,12.9505065 C11.2553832,12.9505065 10.6957391,12.4638595 10.6957391,11.86355 L10.6957391,11.86355 L10.6957391,8.06355 C10.6957391,7.46324049 11.2553832,6.97659348 11.9457391,6.97659348 Z'
    />
  </Icon>
);
