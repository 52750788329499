import {
  ElementType,
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
  RefObject,
  useEffect,
} from 'react';
import { onlyText } from 'react-children-utilities';
import { Icon } from '@frontend/icons';
import { CubeIconSmall, XIconSmall, CaretDownIcon, MultiLocationSmall, CaretUpIcon } from '../../icon';
import { NakedButton } from '../../naked';
import { PolymorphicComponentPropWithRef, PolymorphicComponentPropWithoutRef, PolymorphicRef } from '../../type-utils';
import { useStyles } from '../../use-styles';
import { useTooltip } from '../tooltip';
import { TagChipStylesProps, ChipStylesProps } from './chip.styles';
import type { StatusSwitcherVariants, Variants } from './chip.theme';

export type ChipProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  variant?: Variants;
  className?: string;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  size?: ChipStylesProps['size'];
  editable?: boolean;
  onKeyDown?: KeyboardEventHandler;
  onChange?: ChangeEventHandler;
  trackingId?: string;
  /**
   * ***isResponsive*** should be passed when the chip is part of a container that can get smaller than 120px (such as table cells)
   * Ideally we wouldn't need to pass this prop, but there seems to be a weird behavior if `min()` is used inside `max-width`;
   */
  isResponsive?: boolean;

  /**
   * **containerRef** use in conjuction with isResponsive. When you want the chip to be responsive and show
   * as much text as possible, but respect a max-width. This usually breaks in a flex box, so the containerRef
   * tells it how big it's "max" is.
   */
  containerRef?: RefObject<HTMLElement>;

  maxWidth?: string;
  /**
   * ***hideTooltip***
   * By default when the chip's text is too long it will show a tooltip with the full text on hover.
   * This prop allows the consumer to hide the tooltip and will allow the consumer to use its own tooltip implementation.
   */
  hideTooltip?: boolean;
};

type PolymorphicChipProps<T extends ElementType> = PolymorphicComponentPropWithRef<T, ChipProps>;

type PolymorphicChipType = (<C extends ElementType = 'div'>(props: PolymorphicChipProps<C>) => React.ReactNode) & {
  displayName?: string;
};

const commonEditableStyles = {
  width: 'auto',
  minWidth: '1em',
  gridArea: '1',
  font: 'inherit',
  padding: 0,
  margin: '0',
  resize: 'none',
  background: 'none',
  appearance: 'none',
  border: 'none',
} as const;

export const ChipOptional: PolymorphicChipType = forwardRef(
  <T extends ElementType = 'div'>(
    {
      as,
      children,
      variant = 'primary',
      leftElement,
      rightElement,
      size = 'default',
      trackingId,
      className,
      editable,
      onChange,
      onKeyDown,
      isResponsive,
      maxWidth = '120px',
      hideTooltip,
      containerRef,
      ...rest
    }: PolymorphicChipProps<T> & { maxWidth: string },
    ref: PolymorphicRef<T>
  ) => {
    const Component = as || 'div';
    const [containerWidth, setContainerWidth] = useState<number | false>(false);
    const chipStyles = useStyles('Chip', {
      variant,
      size,
      isResponsive,
      maxWidth: containerWidth ? `${containerWidth}px` : maxWidth,
    });

    const [showTooltip, setShowTooltip] = useState(false);
    const [offsetWidth, setOffsetWidth] = useState<number | undefined>(undefined);
    const {
      Tooltip,
      triggerProps: { ref: triggerRef, ...restTriggerProps },
      tooltipProps,
    } = useTooltip({ initialOpen: false, trigger: 'hover' });
    const spanRef = useRef<HTMLSpanElement | null>(null);

    useLayoutEffect(() => {
      if (!spanRef.current) return;
      // need to trigger a rerender to get the width of the element after it has been rendered
      // otherwise the width will be 0 until a rerender is triggered
      setOffsetWidth(spanRef?.current?.offsetWidth);
    }, [spanRef?.current?.offsetWidth]);

    useEffect(() => {
      if (!containerRef?.current || !isResponsive) {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        if (containerRef.current) {
          const width = containerRef.current.clientWidth;
          setContainerWidth(width);
        }
      });
      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }, [isResponsive]);

    function chipMeasureRef(node: HTMLSpanElement) {
      if (node === null) return;

      spanRef.current = node;
      if (!offsetWidth) return;
      if (offsetWidth < node.scrollWidth && !hideTooltip) {
        setShowTooltip(true);
        triggerRef(node);
      } else {
        setShowTooltip(false);
      }
    }

    const tooltipText = onlyText(children);

    return (
      <Component
        // Ensure that className passed does not override the .chip class
        className={'chip' + (className ? ` ${className}` : '')}
        css={[
          chipStyles,
          editable && {
            width: 'max-content',
          },
        ]}
        {...rest}
        data-trackingid={trackingId}
        ref={ref}
      >
        {leftElement}
        {editable ? (
          <span
            ref={chipMeasureRef}
            css={{
              display: 'inline-grid',
              '::after': {
                content: 'attr(data-value)',
                visibility: 'hidden',
                whiteSpace: 'pre-wrap',
                height: 1,
                ...commonEditableStyles,
              },
            }}
          >
            <input
              autoFocus
              size={1}
              css={{
                ...commonEditableStyles,
                ':focus': {
                  border: 'none',
                  // The outline will be around the chip component instead of the input
                  outline: 'none',
                },
              }}
              className='chip-input'
              {...(hideTooltip ? {} : restTriggerProps)}
              onKeyDown={onKeyDown}
              onChange={(e) => {
                if (e.currentTarget.parentElement) {
                  e.currentTarget.parentElement.dataset.value = e.currentTarget.value;
                }
                onChange?.(e);
              }}
            />
          </span>
        ) : (
          <span className='chip-text' {...(hideTooltip ? {} : restTriggerProps)} ref={chipMeasureRef}>
            {children}
          </span>
        )}
        {showTooltip && tooltipText && <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>}
        {rightElement}
      </Component>
    );
  }
);

ChipOptional.displayName = 'ChipOptional';

const RemovableChip = ({
  onClick,
  disabled,
  variant,
  ...props
}: ChipProps & { onClick: MouseEventHandler<HTMLButtonElement>; disabled?: boolean }) => {
  return (
    <ChipOptional
      variant={disabled ? 'disabled' : variant ? variant : 'interactive'}
      {...props}
      rightElement={
        <NakedButton disabled={disabled} onClick={onClick} css={{ display: 'flex' }}>
          <Icon name='x-tiny' color={disabled ? 'disabled' : 'primary'} />
        </NakedButton>
      }
    />
  );
};

export const PetChip = forwardRef<HTMLDivElement, { leftElement: React.ReactNode; name: string }>(
  ({ leftElement, name, ...rest }, ref) => (
    <ChipOptional variant='neutral' leftElement={leftElement} ref={ref} {...rest}>
      {name}
    </ChipOptional>
  )
);
PetChip.displayName = 'PetChip';

const PersonChip = ({
  onClick,
  variant = 'outline',
  ...props
}: ChipProps & { avatar: ReactNode; onClick?: MouseEventHandler<HTMLButtonElement> }) => {
  return onClick ? (
    <RemovableChip onClick={onClick} {...props} variant='outline' leftElement={props.avatar} />
  ) : (
    <ChipOptional variant={variant} {...props} leftElement={props.avatar} />
  );
};

const LocationChip = (props: ChipProps) => {
  return (
    <ChipOptional {...props} variant={props.variant ?? 'default'} leftElement={<Icon name='location' size={16} />} />
  );
};

const HouseholdChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant={props.variant ?? 'default'} leftElement={<Icon name='crown' size={14} />} />;
};

const SourceChip = (props: ChipProps) => {
  return (
    <ChipOptional {...props} variant={props.variant ?? 'default'} leftElement={<Icon name='data-sync' size={16} />} />
  );
};

const SingleChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant='primaryDark' leftElement={<Icon name='location' size={16} />} />;
};

const MultiChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant='warningDark' leftElement={<MultiLocationSmall size={16} />} />;
};

const DepartmentChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant='default' leftElement={<CubeIconSmall size={16} />} />;
};

export type TagChipProps<C extends MouseEventHandler<HTMLButtonElement> | undefined> = Omit<ChipProps, 'variant'> & {
  color: TagChipStylesProps['color'];
  onClick?: C;
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
  isRemovable?: boolean;
  trackingIds?: Partial<{
    chip: string;
    removeButton: string;
  }>;
};

const TagChip = forwardRef(
  <
    C extends MouseEventHandler<HTMLButtonElement> | undefined,
    E extends ElementType = C extends undefined ? 'div' : 'button'
  >(
    {
      color,
      onClick,
      isRemovable,
      onRemoveClick,
      as,
      trackingIds,
      ...props
    }: PolymorphicComponentPropWithoutRef<E, TagChipProps<C>>,
    ref?: PolymorphicRef<E>
  ) => {
    const tagStyles = useStyles('TagChip', { color, hasOnClick: !!onClick });

    const resolvedAs = as || !!onClick ? 'button' : 'div';

    return (
      <ChipOptional
        as={resolvedAs}
        onClick={onClick}
        css={tagStyles}
        rightElement={
          isRemovable && onRemoveClick ? (
            <NakedButton
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClick(e);
              }}
              css={{
                display: 'flex',
              }}
              trackingId={trackingIds?.removeButton}
            >
              <XIconSmall size={13} />
            </NakedButton>
          ) : undefined
        }
        ref={ref}
        trackingId={trackingIds?.chip}
        {...props}
      />
    );
  }
);
TagChip.displayName = 'TagChip';

type DropdownFilterChipProps = Omit<ChipProps, 'variant' | 'size' | 'rightElement' | 'onClick'> & {
  filterIsActive?: boolean;
  onClick?: MouseEventHandler;
  isOpen?: boolean;
};

const DropdownFilterChip = forwardRef<HTMLButtonElement, DropdownFilterChipProps>(
  ({ filterIsActive = false, isOpen = false, ...props }, ref) => {
    const dropdownFilterStyles = useStyles('DropdownFilterChip', { filterIsActive, isOpen });

    return (
      <ChipOptional
        as='button'
        css={dropdownFilterStyles}
        size='large'
        rightElement={<Icon className='location-caret' name='caret-down-tiny' size={12} />}
        {...props}
        ref={ref}
      />
    );
  }
);
DropdownFilterChip.displayName = 'DropdownFilterChip';

export type StatusSwitcherChipProps = Omit<ChipProps, 'variant' | 'size' | 'rightElement' | 'onClick'> & {
  disabled?: boolean;
  isOpen?: boolean;
  variant: StatusSwitcherVariants;
};

const StatusSwitcherChip = forwardRef<HTMLButtonElement, StatusSwitcherChipProps>(
  ({ disabled = false, isOpen = false, variant = 'default', ...props }, ref) => {
    const statusSwitcherStyles = useStyles('StatusSwitcherChip', { variant: disabled ? 'disabled' : variant });

    return (
      <ChipOptional
        as='button'
        css={statusSwitcherStyles}
        disabled={disabled}
        rightElement={isOpen ? <CaretUpIcon size={8} /> : <CaretDownIcon size={8} />}
        {...props}
        ref={ref}
      />
    );
  }
);
StatusSwitcherChip.displayName = 'StatusSwitcherChip';

const ChipNamespace = Object.assign(ChipOptional, {
  Removable: RemovableChip,
  Location: LocationChip,
  DataSource: SourceChip,
  Department: DepartmentChip,
  Person: PersonChip,
  SingleChip: SingleChip,
  MultiChip: MultiChip,
  Tag: TagChip,
  Pet: PetChip,
  DropdownFilter: DropdownFilterChip,
  StatusSwitcher: StatusSwitcherChip,
  Household: HouseholdChip,
});

export { ChipNamespace as Chip };
