import { useMemo } from 'react';
import { KeyNames } from '../../constants/key-names';
import { useDebouncedBlur } from '../forms/hooks/use-debounced-blur/use-debounced-blur';
import { useFlyoutState } from './use-flyout-state';
import { FlyoutDialog } from './flyout-dialog.component';
import { ToggleCallback } from './types';

type CustomTriggerRef = React.MutableRefObject<HTMLElement | null>;

type UseDialogProps<TFlyoutElement extends HTMLElement, TTriggerElement extends HTMLElement> = {
  clickOutsideIgnoreRefs?: React.MutableRefObject<any>[];
  clickOutsideCaptureClick?: boolean;
  onBlur?: (e: React.FocusEvent<TTriggerElement>) => void;
  onFocus?: (e: React.FocusEvent<TFlyoutElement>) => void;
  onToggle?: ToggleCallback<TFlyoutElement>;
  trigger?: React.MutableRefObject<TTriggerElement | null>;
};

/**
 *
 * @deprecated use usePopoverDialog instead
 */
export function useFlyoutDialog<
  TFlyoutElement extends HTMLElement = HTMLElement,
  TTriggerElement extends HTMLElement = HTMLButtonElement
>({
  onBlur,
  onFocus,
  onToggle,
  clickOutsideCaptureClick,
  clickOutsideIgnoreRefs,
  trigger,
}: UseDialogProps<TFlyoutElement, TTriggerElement>) {
  const { active, flyoutId, flyoutRef, setActive, triggerRef } = useFlyoutState<TFlyoutElement, TTriggerElement>({
    onToggle,
    captureClick: clickOutsideCaptureClick,
    ignoreRefs: clickOutsideIgnoreRefs,
  });
  const { blur, focus } = useDebouncedBlur({
    //@ts-ignore -> undefined error, but signatures are the same for e in both props
    onBlur,
    //@ts-ignore
    onFocus,
  });

  return useMemo(() => {
    const id = `flyout-dialog-${flyoutId.current}`;
    const labelId = `${id}-label`;
    const close = (customTrigger?: CustomTriggerRef) => {
      setActive(false);
      setTimeout(() => {
        if (customTrigger?.current) {
          customTrigger.current.focus();
        } else if (trigger?.current) {
          trigger.current.focus();
        } else {
          triggerRef.current?.focus();
        }
      }, 0);
    };
    const open = () => {
      setActive(true);
    };
    return {
      active,
      close,
      debouncedBlur: () => blur(),
      debouncedFocus: () => focus(),
      FlyoutDialog,
      flyoutHeaderProps: { id: labelId },
      flyoutProps: {
        'aria-labelledby': labelId,
        'aria-live': 'polite' as const,
        'aria-modal': 'true' as const,
        id,
        onFocus: focus,
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === KeyNames.Escape) close();
        },
        ref: flyoutRef,
        role: 'dialog' as const,
        tabIndex: -1,
      },
      open,
      toggle: setActive,
      triggerProps: {
        onBlur: () => blur(),
        onFocus: () => focus(),
        onClick: () => {
          setActive((current) => !current);
        },
        ref: triggerRef,
      },
    };
  }, [active, blur, focus]);
}
