import { css } from '@emotion/react';
import { INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $setBlocksType, $wrapNodes } from '@lexical/selection';
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical';
import { BlockAction } from '../../molecules';
import { useToolbarState } from '../../providers';

type BlockTypes = 'paragraph' | 'h1' | 'h2' | 'ul' | 'ol' | 'quote';

export const BlockType = () => {
  const [editor] = useLexicalComposerContext();
  const { blockType } = useToolbarState(['blockType']);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatOrderedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
  };

  const formatBlock = (blockType: BlockTypes) => {
    switch (blockType) {
      case 'h1':
        formatLargeHeading();
        break;
      case 'h2':
        formatSmallHeading();
        break;
      case 'paragraph':
        formatParagraph();
        break;
      case 'ul':
        formatBulletList();
        break;
      case 'ol':
        formatOrderedList();
        break;
      case 'quote':
        formatQuote();
        break;
    }
  };

  return (
    <BlockAction
      css={css`
        flex: 1;
        min-width: 160px;
        max-width: 200px;
      `}
      value={blockType}
      onChange={(blockType) => formatBlock(blockType)}
    />
  );
};
