import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { DynamicFieldIconSmall } from '../../../icon';
import { Button } from '../../button';
import { PopoverDialog, usePopoverDialog } from '../../popover';
import { ActionPressedChip } from '../atoms';

export type DynamicFieldAttributes = { label: string; symbol: string; val?: string };

type DynamicFieldActionProps = {
  label?: ReactNode;
  fields: DynamicFieldAttributes[];
  onSelect: (selection: { symbol: string; label: string; val?: string }) => void;
} & Pick<React.ComponentProps<typeof ActionPressedChip>, 'showBoxShadow' | 'compact' | 'css' | 'isActive'>;

export const DynamicFieldAction = ({
  label,
  fields,
  showBoxShadow = true,
  compact = true,
  isActive,
  onSelect,
  ...rest
}: DynamicFieldActionProps) => {
  const { getDialogProps, getTriggerProps, isOpen } = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-start',
    middlewareOptions: {
      offset: {
        crossAxis: 0,
        mainAxis: 12,
      },
    },
  });

  return (
    <>
      <ActionPressedChip
        showBoxShadow={showBoxShadow}
        isActive={isOpen || isActive}
        compact={compact}
        {...getTriggerProps()}
        {...rest}
      >
        {label ?? (
          <>
            <DynamicFieldIconSmall style={{ marginRight: theme.spacing(1) }} /> Dynamic Field
          </>
        )}
      </ActionPressedChip>
      <PopoverDialog
        {...getDialogProps()}
        returnFocus={false}
        css={css`
          max-width: 400px;
          width: 400px;
          border-radius: ${theme.borderRadius.medium};
          button {
            margin-right: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(1)};
            height: auto;
            width: auto;
            padding: 2px 8px;
          }
        `}
      >
        {fields.map((field) => (
          <Button
            key={field.symbol}
            css={{
              fontWeight: theme.font.weight.regular,
              height: theme.spacing(3),
              fontSize: theme.fontSize(12),
            }}
            size='small'
            onClick={() => onSelect(field)}
            variant='secondary'
          >
            {field.label}
          </Button>
        ))}
      </PopoverDialog>
    </>
  );
};
