24;
/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ArchiveIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M4.99616 22H19.0038C20.6586 22 22 20.6541 22 18.9938V6.22065C22 5.42388 21.6848 4.65968 21.1235 4.09599L19.9145 2.88158C19.3525 2.31714 18.5899 2 17.7948 2H6.20546C5.41037 2 4.64789 2.31709 4.08592 2.88144L2.87663 4.09587C2.31531 4.65958 2 5.42383 2 6.22067V18.9938C2 20.6541 3.34142 22 4.99616 22ZM19.5032 18.9938C19.5032 19.2705 19.2797 19.4948 19.0038 19.4948H4.99616C4.72038 19.4948 4.4968 19.2705 4.4968 18.9938V8.15095H19.5032V18.9938ZM5.85221 4.65212L4.86276 5.64575H19.1374L18.148 4.65214C18.0544 4.55806 17.9273 4.5052 17.7948 4.5052H6.20546C6.07294 4.5052 5.94587 4.55805 5.85221 4.65212ZM13.2473 11.1078C13.2473 10.416 12.6884 9.85519 11.9989 9.85519C11.3094 9.85519 10.7505 10.416 10.7505 11.1078V14.0818L9.62434 13.3205C9.05254 12.9339 8.2767 13.0857 7.89145 13.6594C7.50622 14.2331 7.65746 15.0116 8.22926 15.3981L11.0208 17.2851C11.073 17.3205 11.1267 17.3527 11.1817 17.3818C11.4007 17.5722 11.6863 17.6874 11.9989 17.6874C12.3139 17.6874 12.6016 17.5704 12.8213 17.3773C12.8733 17.3493 12.9242 17.3187 12.9739 17.2851L15.7653 15.3981C16.3371 15.0116 16.4884 14.2331 16.1031 13.6594C15.7178 13.0857 14.942 12.9339 14.3702 13.3205L13.2473 14.0796V11.1078Z'
    />
  </Icon>
);
