import { type ElementFormatType } from 'lexical';
import { createProvider } from '@frontend/store';

type SelectedElementFormatType = Extract<ElementFormatType, 'left' | 'center' | 'right'>;
type BlockTypes = 'paragraph' | 'h1' | 'h2' | 'ul' | 'ol' | 'quote';

type ToolbarState = {
  canUndo: boolean;
  canRedo: boolean;
  blockType: BlockTypes;
  fontColor: string;
  fontSize: string;
  fontFamily: string;
  isLink: boolean;
  isBold: boolean;
  isStrikethrough: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  linkUrl: string;
  elementFormat: SelectedElementFormatType;
};

type ToolbarActions = {
  setCanUndo: (canUndo: boolean) => void;
  setCanRedo: (canRedo: boolean) => void;
  setBlockType: (blockType: BlockTypes) => void;
  setFontColor: (fontColor: string) => void;
  setFontSize: (fontSize: string) => void;
  setFontFamily: (fontFamily: string) => void;
  setIsLink: (isLink: boolean) => void;
  setIsBold: (isBold: boolean) => void;
  setIsStrikethrough: (isStrikethrough: boolean) => void;
  setIsItalic: (isItalic: boolean) => void;
  setIsUnderline: (isUnderline: boolean) => void;
  setLinkUrl: (linkUrl: string) => void;
  setElementFormat: (elementFormat: SelectedElementFormatType) => void;
};

export const { Provider: ToolbarStateProvider, useStore: useToolbarState } = createProvider<
  ToolbarState & ToolbarActions
>()((set) => ({
  canUndo: false,
  canRedo: false,
  blockType: 'paragraph',
  fontColor: '#000',
  fontSize: '15px',
  fontFamily: 'Arial',
  isLink: false,
  isBold: false,
  isStrikethrough: false,
  isItalic: false,
  isUnderline: false,
  linkUrl: '',
  elementFormat: 'left',

  setCanUndo: (canUndo) => {
    set(() => ({ canUndo }));
  },
  setCanRedo: (canRedo) => {
    set(() => ({ canRedo }));
  },
  setBlockType: (blockType) => {
    set(() => ({ blockType }));
  },
  setFontColor: (fontColor) => {
    set(() => ({ fontColor }));
  },
  setFontSize: (fontSize) => {
    set(() => ({ fontSize }));
  },
  setFontFamily: (fontFamily) => {
    set(() => ({ fontFamily }));
  },
  setIsLink: (isLink) => {
    set(() => ({ isLink }));
  },
  setIsBold: (isBold) => {
    set(() => ({ isBold }));
  },
  setIsStrikethrough: (isStrikethrough) => {
    set(() => ({ isStrikethrough }));
  },
  setIsItalic: (isItalic) => {
    set(() => ({ isItalic }));
  },
  setIsUnderline: (isUnderline) => {
    set(() => ({ isUnderline }));
  },
  setLinkUrl: (linkUrl) => {
    set(() => ({ linkUrl }));
  },
  setElementFormat: (elementFormat) => {
    set(() => ({ elementFormat }));
  },
}));
