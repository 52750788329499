import React, { forwardRef, Children, KeyboardEvent, ReactNode } from 'react';
import { motion } from 'framer-motion';
import { onlyText } from 'react-children-utilities';
import { KeyNames } from '../../constants';
import { PolymorphicRef, PolymorphicComponentPropWithRef } from '../../type-utils';
import { useStyles } from '../../use-styles';
import { ContentColumn } from './atoms/content-column/list-row-content-column.component';
import { LeadingColumn } from './atoms/leading-column/list-row-leading-column';
import { TrailingColumn } from './atoms/trailing-column/list-row-trailing-column.component';

export type ListRowProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  { isSelected?: boolean; trackingId?: string }
>;

type ListRowComponent = <C extends React.ElementType = 'li'>(props: ListRowProps<C>) => React.ReactNode;

export const ListRowOptional: ListRowComponent = forwardRef(
  <C extends React.ElementType = 'li'>(
    { children, as, isSelected = false, trackingId, ...rest }: ListRowProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const reactChildren: ReactNode[] = Children.toArray(children);
    const Component = as || 'li';
    const { onClick } = rest;
    const styles = useStyles('ListRow', 'Row', {
      isClickable: !!onClick,
      isSelected,
    });

    const indicatorStyles = useStyles('ListRow', 'Indicator');

    const contentColumnComponent = reactChildren.filter(
      (child) => child?.['type']?.displayName === 'ContentColumn'
    )[0]?.['props'].children;

    return (
      <Component
        css={styles}
        ref={ref}
        className='list-row'
        onClick={() => onClick?.()}
        aria-label={onlyText(contentColumnComponent as React.ReactNode) || 'List row'}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === KeyNames.Enter || e.key === KeyNames.Space) {
            onClick?.(e);
          }
        }}
        data-trackingid={trackingId}
        {...rest}
      >
        {children}
        {isSelected && (
          <motion.span
            layoutId='list-row-indicator'
            initial={false}
            layout='position'
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            css={indicatorStyles}
          />
        )}
      </Component>
    );
  }
);

const ListRowNamespace = Object.assign(ListRowOptional, {
  Lead: LeadingColumn,
  Content: ContentColumn,
  Trail: TrailingColumn,
});

export { ListRowNamespace as ListRow };
