import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $filter, $getNearestBlockElementAncestorOrThrow } from '@lexical/utils';
import type { LexicalCommand, LexicalEditor, RangeSelection } from 'lexical';
import {
  $createRangeSelection,
  $getSelection,
  $isBlockElementNode,
  $isRangeSelection,
  $normalizeSelection__EXPERIMENTAL,
  COMMAND_PRIORITY_EDITOR,
  INDENT_CONTENT_COMMAND,
  INSERT_TAB_COMMAND,
  KEY_TAB_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';

function shouldIndentInsteadOfTab(selection: RangeSelection): boolean {
  const selectedNodes = selection.getNodes();
  const indentableBlockNodes = $filter(selectedNodes, (node) => {
    return $isBlockElementNode(node) && node.canIndent() ? node : null;
  });

  if (indentableBlockNodes.length > 0) {
    return true;
  }

  const [startPoint] = selection.isBackward()
    ? [selection.focus, selection.anchor]
    : [selection.anchor, selection.focus];
  const firstNode = startPoint.getNode();
  const firstBlock = $getNearestBlockElementAncestorOrThrow(firstNode);

  if (firstBlock.canIndent()) {
    const firstBlockKey = firstBlock.getKey();
    let selectionAtStart = $createRangeSelection();
    selectionAtStart.anchor.set(firstBlockKey, 0, 'element');
    selectionAtStart.focus.set(firstBlockKey, 0, 'element');
    selectionAtStart = $normalizeSelection__EXPERIMENTAL(selectionAtStart);

    if (selectionAtStart.anchor.is(startPoint)) {
      return true;
    }
  }

  return false;
}

function registerTabIndentationHandler(editor: LexicalEditor) {
  return editor.registerCommand<KeyboardEvent>(
    KEY_TAB_COMMAND,
    (event) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) {
        return false;
      }

      event.preventDefault();
      const command: LexicalCommand<void> = shouldIndentInsteadOfTab(selection)
        ? event.shiftKey
          ? OUTDENT_CONTENT_COMMAND
          : INDENT_CONTENT_COMMAND
        : INSERT_TAB_COMMAND;

      return editor.dispatchCommand(command, undefined);
    },
    COMMAND_PRIORITY_EDITOR
  );
}

export function TabIndentationPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return registerTabIndentationHandler(editor);
  }, [editor]);

  return null;
}
