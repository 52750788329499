import { ReactNode, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { StepperCardProvider } from '../provider/stepper-card.provider';
import { useStepperContext } from '../provider/stepper.provider';
import * as styles from '../stepper.styles';

export type StepperCardProps = {
  step: number;
  stepValue?: ReactNode;
  isValid?: boolean;
  isOptionalStep?: boolean;
  isHorizontal?: boolean;
  isVerticalSingleView?: boolean;
  children: ReactNode | ReactNode[];
  preventDefaultOnClick?: boolean;
  onClick?: (step: number) => void;
  skipAnimation?: boolean;
};

export const StepperCard = ({
  step = 0,
  stepValue,
  isValid,
  isOptionalStep,
  isHorizontal,
  isVerticalSingleView,
  children,
  preventDefaultOnClick = false,
  onClick,
  skipAnimation,
  ...rest
}: Partial<StepperCardProps>) => {
  const { stepStatus, handleStepperCard, isStepperCompleted } = useStepperContext();

  const card = useMemo(() => {
    const status = isStepperCompleted ? 'finished' : stepStatus[step];
    return (
      <AnimatePresence>
        <motion.section
          key='step-card'
          initial='inactive'
          animate={skipAnimation ? undefined : status}
          variants={
            skipAnimation
              ? undefined
              : {
                  active: {
                    height: 'auto',
                  },
                  currActive: {
                    height: 'auto',
                  },
                  errorActive: {
                    height: 'auto',
                  },
                  inactive: {
                    height: 82,
                  },
                  error: {
                    height: 82,
                  },
                  completed: {
                    height: 82,
                  },
                  finished: {
                    height: 'auto',
                  },
                }
          }
          transition={skipAnimation ? undefined : { ease: 'easeInOut', duration: 0.3 }}
          id={`step-${step}`}
          aria-current={status === 'active' || status === 'currActive' || status === 'errorActive'}
          css={styles.stepCard(status, !preventDefaultOnClick || !!onClick)}
          className={`step-card ${status}-step`}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(step);
            if (!isHorizontal && !isVerticalSingleView && !preventDefaultOnClick) {
              handleStepperCard(step);
            }
          }}
          {...rest}
        >
          {children}
        </motion.section>
      </AnimatePresence>
    );
  }, [stepStatus, stepValue, isValid, step, isHorizontal, children]);

  return (
    <StepperCardProvider step={step} stepValue={stepValue}>
      {isHorizontal || isVerticalSingleView
        ? (stepStatus[step] === 'active' || stepStatus[step] === 'currActive' || stepStatus[step] === 'errorActive') &&
          card
        : card}
    </StepperCardProvider>
  );
};

// we need this to differentiate it when we filter thru the children.
// Check out the stepper.component file to see where we filter by displayName
// the reason we can't filter thru Component Name like 'StepperCard' is because it gets minified in a prod build
StepperCard.displayName = 'StepperCard';
